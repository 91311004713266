import style from "../css/befireprotranslator.scss";
import React, { useEffect, useState } from "react";
import Expanse from "react-expanse";
import { useIntl } from "react-intl";
import image51 from "../images/image51.png";
import image52 from "../images/image52.png";
import image53 from "../images/image53.png";
import image54 from "../images/image54.png";

const Beforeprotranslator = props => {
  const [blank, setBlank] = useState(false);
  const [service, setService] = useState(false);
  const [business, setBusiness] = useState(false);
  const [support, setSupport] = useState(false);
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const goProTranslator = () => {
    props.history.push("/protranslator");
  };
  return (
    <>
      <div className={style.box}>
        <p>{t("apply.before.apply")}</p>
        <div className={style.body}>
          <div className={style.first}>
            <p>{t("apply.before.Register")}</p>
            <span>{t("apply.before.You")}</span>
            <button onClick={goProTranslator}>{t("apply.before.Submit")}</button>
          </div>
          <div>
            <p>{t("apply.before.Criteria")}</p>
            <span>{t("apply.before.In")}</span>
            <ul>
              <li>{t("apply.before.Translating-related")}</li>
              <li>{t("apply.before.More")}</li>
              <li>{t("apply.before.Moretwo")}</li>
            </ul>
          </div>
          <div>
            <p>{t("apply.before.Resume")}</p>
            <span>{t("apply.before.Application")}</span>
          </div>
          <div className={style.last}>
            <p>{t("apply.before.How")}</p>
            <div>
              <div>
                <p>{t("apply.before.1")}</p>
                <img src={image52} alt="" />
              </div>
              <div>
                <p>{t("apply.before.2")}</p>
                <img src={image51} alt="" />
              </div>
              <div>
                <p>{t("apply.before.3")}</p>
                <img src={image53} alt="" />
              </div>
              <div>
                <p>{t("apply.before.4")}</p>
                <img src={image54} alt="" />
              </div>
            </div>
            <button onClick={goProTranslator}>{t("apply.before.Submit")}</button>
          </div>
        </div>
      </div>
      <div className={style.foot}>
        <div className={style.mobiletop}>
          <div onClick={() => setBlank(!blank)}>
            Blank<i className={!blank ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
          </div>
          <Expanse show={blank}>
            <div className={style.topexpanse}>
              <span>{t("AboutUs")}</span>
            </div>
          </Expanse>
          <div onClick={() => setService(!service)}>
            {t("Service")}
            <i className={!service ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
          </div>
          <Expanse show={service}>
            <div className={style.topexpanse}>
              <span>{t("LanguageService")}</span>
              <span>{t("ProTranslation")}</span>
              <span>{t("YouTubeSubtitle")}</span>
            </div>
          </Expanse>
          <div onClick={() => setBusiness(!business)}>
            {t("Business")}
            <i className={!business ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
          </div>
          <Expanse show={business}>
            <div className={style.topexpanse}>
              <span>{t("VideoTranslation")}</span>
              <span>{t("BusinessTranslation")}</span>
            </div>
          </Expanse>
          <div onClick={() => setSupport(!support)}>
            {t("Support")}
            <i className={!support ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
          </div>
          <Expanse show={support}>
            <div className={style.topexpanse}>
              <span>{t("BlankSupport")}</span>
              <span>{t("Terms")}</span>
              <span>{t("Privacy")}</span>
            </div>
          </Expanse>
        </div>
        <div className={style.top}>
          <div>
            <p>Blank</p>
            <span>{t("AboutUs")}</span>
          </div>
          <div>
            <p>{t("Service")}</p>
            <span>{t("LanguageService")}</span>
            <span>{t("ProTranslation")}</span>
            <span>{t("YouTubeSubtitle")}</span>
          </div>
          <div>
            <p>{t("Business")}</p>
            <span>{t("VideoTranslation")}</span>
            <span>{t("BusinessTranslation")}</span>
          </div>
          <div>
            <p>{t("Support")}</p>
            <span>{t("BlankSupport")}</span>
            <span>{t("Terms")}</span>
            <span>{t("Privacy")}</span>
          </div>
        </div>
        <div className={style.bottom}>
          <div>
            <p>{t("Employment")}</p>
            <span>blank.translate@gmail.com</span>
          </div>
          <div>
            <p>{t("ceo")}</p>
            <span>{t("kim")}</span>
          </div>
          <div>
            <p>{t("BusinessTranslationInquiry")}</p>
            <span>blank.translate@gmail.com</span>
          </div>
          <div>
            <p>{t("Commerce")}</p>
            <span>2021-GwangjuGwangsan-0287</span>
          </div>
          <div>
            <p>{t("Number")}</p>
            <span>236-38-00655</span>
          </div>
          <div>
            <p>{t("Address")}</p>
            <span>{t("61472")}</span>
          </div>
        </div>
        <span>©2024 Beyondary. All rights reserved.</span>
      </div>
      <div className={style.after}></div>
    </>
  );
};

export default Beforeprotranslator;
