import style from "../css/translation.scss";
import { useIntl } from "react-intl";
import { message, Tabs, Modal } from "antd";
import React, { useState, useRef } from "react";
import Expanse from "react-expanse";
import pdf from "../images/PDF.png";
import txt from "../images/TXT.png";
import doc from "../images/DOC.png";
import srt from "../images/SRT.png";
import hwp from "../images/HWP.png";
import icon from "../images/Icon.png";
import emailjs from "@emailjs/browser";
import Countable from "countable";
import mammoth from "mammoth";
import { lowerFirst } from "lodash";
import * as Parser from "js-resume-parser";
import { readDoc } from "word2text";

const Translation = props => {
  emailjs.init("MgPT_AFf_ROdxwkzr");
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const [sou, setSou] = useState("");
  const [tar, setTar] = useState("");
  const [rad, setRad] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef();
  const source = useRef();
  const target = useRef();
  const file = useRef();
  const Cpmpany = useRef();
  const Full = useRef();
  const Email = useRef();
  const Phone = useRef();
  const url = useRef();
  const [turl, setTurl] = useState("");
  const trueurl = useRef();
  const [ifurl, setIfurl] = useState(false);
  const [ifrequest, setIfrequest] = useState(false);
  const [num, setNum] = useState(true);
  const [speci, setSpeci] = useState();
  const [des, setDes] = useState(false);
  const [i, setI] = useState(false);
  const [fileimg, setFileimg] = useState();
  const [words, setWords] = useState(0);
  const [price, setPrice] = useState(0);

  const specialty = [
    { value: t("apply.pro.Politics") },
    { value: t("apply.pro.Economics") },
    { value: t("apply.pro.Law") },
    { value: t("apply.pro.Medical") },
    { value: t("apply.pro.Science") },
    { value: t("apply.pro.Engineering") },
    { value: t("apply.pro.IT") },
    { value: t("apply.pro.Construction") },
    { value: t("apply.pro.Publication") },
    { value: t("apply.pro.Game") },
    { value: t("apply.pro.Humanities") },
    { value: t("apply.pro.International") },
    { value: t("apply.pro.Literature") },
    { value: t("apply.pro.Education") },
    { value: t("apply.pro.Culture") },
    { value: t("apply.pro.Beauty") },
    { value: t("apply.pro.Entertainment") },
    { value: t("apply.pro.Life") },
    { value: t("apply.pro.Travel") },
    { value: t("apply.pro.commerce") },
  ];

  const changeLanague = e => {
    const ea = e.target;
    source.current.value = "English";
    if (ea.id === "source") {
      if (ea.innerText === "English") {
        setSou("English");
        setTar("한국어 ");
      } else if (ea.innerText === "한국어 ") {
        setSou("한국어 ");
        setTar("English");
      }
    } else if (ea.id === "target") {
      if (ea.innerText === "English") {
        setSou("한국어 ");
        setTar("English");
      } else if (ea.innerText === "한국어 ") {
        setSou("English");
        setTar("한국어 ");
      }
    }
  };
  const success = () => {
    message.success({
      content: t("Please.fill"),
      className: style.iswarning,
      style: {
        marginTop: "80px",
      },
    });
  };

  const changeRadio = e => {
    setRad(e.target.id);
  };

  const changeSpecialty = e => {
    setSpeci(e.target.innerText);
  };

  const error = () => {
    message.error("Send failure");
  };

  const successyes = () => {
    message.success("Loding!!");
  };

  const filewarn = () => {
    message.error("The file size is limited to 2 MB");
  };

  const sendEmail = e => {
    e.preventDefault();
    if (tar === "" || sou === "" || rad === 0 || Cpmpany.current.value === "" || Full.current.value === "" || Email.current.value === "" || Phone.current.value === "") {
      success();
    } else {
      if (num) {
        if (file.current.value === "") {
          success();
        } else {
          successyes();
          emailjs.sendForm("service_rmh93g9", "template_db6i50s", form.current, "MgPT_AFf_ROdxwkzr").then(
            result => {
              props.history.push({
                pathname: "/beensent",
                query: {
                  id: "tran",
                },
              });
            },
            error => {
              error();
            }
          );
        }
      } else if (!num) {
        if (trueurl.current.value === "") {
          success();
        } else {
          successyes();
          emailjs.sendForm("service_rmh93g9", "template_db6i50s", form.current, "MgPT_AFf_ROdxwkzr").then(
            result => {
              props.history.push({
                pathname: "/beensent",
                query: {
                  id: "tran",
                },
              });
            },
            error => {
              error();
            }
          );
        }
      }
    }
  };

  const reimport = e => {
    const id = e.target.id;
    if (id === "url") {
      setIsModalOpen(true);
    } else if (id === "file") {
      document.getElementById("fileinput")?.click();
    }
  };
  const reselect = e => {
    setTurl("");
    setIfrequest(false);
    setPrice(0);
    setWords(0);
    if (url.current.value) {
      url.current.value = "";
    } else if (file.current.value) {
      file.current.value = "";
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = e => {
    if (url.current.value !== "") {
      setTurl(url.current.value);
      setIfrequest(true);
      setIsModalOpen(false);
    } else {
      success();
      return;
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIfurl(false);
    url.current.value = "";
  };
  const changeUrl = e => {
    if (e.target.value === "") {
      setIfurl(false);
    } else {
      setIfurl(true);
    }
  };
  const onClickfile = e => {
    file.current.value = "";
    if (e === 1) {
      setNum(true);
    } else if (e === 2) {
      setNum(false);
    }
    setTurl("");
    // console.log(trueurl);
  };
  const Count = n => {
    // console.log(n);
    setWords(n);
    const p1 = n * (9 / 100);
    const p = p1.toFixed(2);
    setPrice(p);
  };
  const changeFile = e => {
    // console.log(e);
    let val = "";
    let str = "";
    let str2 = "";
    const fileMaxSize = 1024 * 1024 * 2;
    const value = e.target.value;
    const size = e.target.files[0].size;
    if (size <= fileMaxSize) {
      let filename = value.replace(/.+\\/, "");
      let filetype = value.replace(/.+\./, "");
      const file = e.target.files[0];
      if (filetype === "docx") {
        const reader = new FileReader();
        reader.onload = function (e) {
          var arrayBuffer = e.target.result;
          mammoth.extractRawText({ arrayBuffer: arrayBuffer }).then(function (resultObject) {
            // console.log(resultObject.value);
            str = resultObject.value.replace(/\s*/g, "").replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g, "");
            str2 = resultObject.value.replace(/[^a-zA-Z]/g, "");
            const len = parseInt(str.length / 2);
            // console.log(str, str.length, str2, str2.length, len);
            if (str2.length >= len) {
              setSou("English");
              setTar("한국어 ");
              Countable.count(resultObject.value, counter => Count(counter.words));
            } else {
              setSou("한국어 ");
              setTar("English");
              const p1 = str.length * (9 / 100);
              const p = p1.toFixed(2);
              setWords(str.length);
              setPrice(p);
            }
          });
        };
        reader.readAsArrayBuffer(file);
      } else if (filetype === "pdf") {
        // console.log(e);
        Parser.getDataFromPDF(file).then(data => {
          str = data.text.replace(/\s*/g, "").replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g, "");
          str2 = data.text.replace(/[^a-zA-Z]/g, "");
          const len = parseInt(str.length / 2);
          // console.log(str, str.length, str2, str2.length, len);
          if (str2.length >= len) {
            setSou("English");
            setTar("한국어 ");
            Countable.count(data.text, counter => Count(counter.words));
          } else {
            setSou("한국어 ");
            setTar("English");
            const p1 = str.length * (9 / 100);
            const p = p1.toFixed(2);
            console.log(str);
            setWords(str.length);
            setPrice(p);
          }
        });
      } else if (filetype === "doc") {
        readDoc(file).then(res => {
          str = res.text.replace(/\s*/g, "").replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g, "");
          str2 = res.text.replace(/[^a-zA-Z]/g, "");
          const len = parseInt(str.length / 2);
          // console.log(str, str.length, str2, str2.length, len);
          if (str2.length >= len) {
            setSou("English");
            setTar("한국어 ");
            Countable.count(res.text, counter => Count(counter.words));
          } else {
            setSou("한국어 ");
            setTar("English");
            const p1 = str.length * (9 / 100);
            const p = p1.toFixed(2);
            setWords(str.length);
            setPrice(p);
          }
        });
      } else {
        const reader = new FileReader();
        reader.readAsText(file, "utf-8");
        reader.onload = function (e) {
          val = e.target.result;
          // console.log(val);
          str = val
            .replace(/\s*/g, "")
            .replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、-]/g, "")
            .replace(/[0-9]+/g, "");
          // const str3 = val.replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、-]/g, "").replace(/[0-9]+/g, "");
          str2 = val.replace(/[^a-zA-Z]/g, "");
          const len = parseInt(str.length / 2);
          if (str2.length >= len) {
            setSou("English");
            setTar("한국어 ");
            Countable.count(val, counter => Count(counter.words));
          } else {
            setSou("한국어 ");
            setTar("English");
            const p1 = str.length * (9 / 100);
            const p = p1.toFixed(2);
            setWords(str.length);
            setPrice(p);
          }
        };
      }

      switch (filetype) {
        case "doc":
          setFileimg(doc);
          break;
        case "docx":
          setFileimg(doc);
          break;
        case "txt":
          setFileimg(txt);
          break;
        case "srt":
          setFileimg(srt);
          break;
        case "hwp":
          setFileimg(hwp);
          break;
        case "pdf":
          setFileimg(pdf);
          break;
        default:
          setFileimg("");
      }
      setTurl(filename);
      setIfrequest(true);
    } else {
      filewarn();
      file.current.value = "";
    }
  };
  const changeCheck = e => {
    const id = e.target.id;
    const checked = e.target.checked;
    if (id === "d") {
      if (checked) {
        setDes(true);
      } else {
        setDes(false);
      }
    } else if (id === "i") {
      if (checked) {
        setI(true);
      } else {
        setI(false);
      }
    }
  };
  return (
    <>
      <div className={style.box}>
        <div className={style.header}>
          <p>{t("tran.Request")}</p>
          <span>{t("tran.Request.Select")}</span>
        </div>
        <form encType="multipart/form-data" ref={form} onSubmit={sendEmail}>
          <fieldset className={style.left}>
            <label style={{ marginTop: 0 }}>{t("tran.before.Input")}</label>
            <Tabs onTabClick={onClickfile} defaultActiveKey="1" className={ifrequest ? style.fileyes : style.file}>
              <Tabs.TabPane tab={t("tran.before.File")} key="1">
                <input id="fileinput" onChange={changeFile} className={style.upload} ref={file} type="file" name="my_file" accept=".pdf,.txt,.srt,.hwp,.doc,.docx" />
                <div className={style.uploaddiv}>
                  <p>
                    <i className="ri-add-line"></i>
                    {t("tran.before.Drag")}
                  </p>
                  <span>{t("tran.before.Fileformat")}</span>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("tran.before.Subtitle")} key="2">
                <div className={style.uploaddiv} onClick={showModal}>
                  <p>
                    <i className="ri-link"></i>
                    {t("tran.before.Click")}
                  </p>
                  <span>{t("tran.before.The")}</span>
                </div>
              </Tabs.TabPane>
            </Tabs>
            {!num ? (
              <div className={ifrequest ? style.requestinfo : style.requestinfono}>
                <div className={style.requesttop}>
                  <div>
                    <i className="ri-video-line"></i>
                    <span>{turl}</span>
                  </div>
                  <div>
                    <span onClick={reselect} style={{ color: "#2C6ECB", cursor: "pointer" }}>
                      {t("tran.Reselect")}
                    </span>
                    <span id="url" onClick={reimport} style={{ color: "#2C6ECB", cursor: "pointer" }}>
                      {t("tran.Re")}
                    </span>
                  </div>
                </div>
                <div className={style.requestbuttom}>
                  <div>
                    {!des ? <div></div> : <i className="ri-checkbox-fill"></i>}
                    <input id="d" onChange={changeCheck} type="checkbox" value={t("description")} />
                    <span>{t("description")}</span>
                  </div>
                  <div>
                    {!i ? <div></div> : <i className="ri-checkbox-fill"></i>}
                    <input id="i" onChange={changeCheck} type="checkbox" value={t("I")} />
                    <span>{t("I")}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={ifrequest ? style.requestinfo : style.requestinfono}>
                <div className={style.requesttop} style={{ border: "none" }}>
                  <div>
                    <img src={fileimg} alt="" />
                    <span>{turl}</span>
                  </div>
                  <div>
                    <span onClick={reselect} style={{ color: "#2C6ECB", cursor: "pointer" }}>
                      {t("tran.Reselect")}
                    </span>
                    <span id="file" onClick={reimport} style={{ color: "#2C6ECB", cursor: "pointer" }}>
                      {t("tran.Re")}
                    </span>
                  </div>
                </div>
                <div className={style.requestbuttom}>
                  <div className={style.requestwarn}>
                    <img src={icon} alt="" />
                    <span>{t("tran.warning")}</span>
                  </div>
                </div>
              </div>
            )}

            <Modal
              className={style.modal}
              title={t("tran.before.Paste")}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <button key={1} className={style.cancel} onClick={handleCancel}>
                  {t("cancel")}
                </button>,
                <button key={2} disabled={ifurl ? false : true} className={ifurl ? style.next : style.nextno} onClick={handleOk}>
                  {t("apply.pro.Next")}
                </button>,
              ]}
            >
              <input onChange={changeUrl} ref={url} type="url" />
            </Modal>

            <input ref={trueurl} style={{ height: 0, overflow: "hidden", border: "none" }} name="my_url" type="text" value={turl} readOnly />
            <label>{t("tran.Selectlanguage")}</label>
            <fieldset className={style.select}>
              <div>
                <input name="my_source" ref={source} value={sou} type="text" placeholder={t("index.contact.Source")} readOnly />
                <ul>
                  <li id="source" onClick={changeLanague}>
                    English
                    {sou === "English" ? <i className="ri-check-line"></i> : ""}
                  </li>
                  <li id="source" onClick={changeLanague}>
                    한국어
                    {sou === "한국어 " ? <i className="ri-check-line"></i> : ""}
                  </li>
                </ul>
              </div>
              <i className="ri-arrow-right-line"></i>
              <div>
                <input name="my_target" ref={target} value={tar} type="text" placeholder={t("index.contact.Target")} readOnly />
                <ul>
                  <li id="target" onClick={changeLanague}>
                    English
                    {tar === "English" ? <i className="ri-check-line"></i> : ""}
                  </li>
                  <li id="target" onClick={changeLanague}>
                    한국어
                    {tar === "한국어 " ? <i className="ri-check-line"></i> : ""}
                  </li>
                </ul>
              </div>
            </fieldset>
            <label>{t("tran.Selectservice")}</label>
            <fieldset className={style.radio}>
              <div className={rad !== 1 ? "" : style.check}>
                <input onClick={changeRadio} type="radio" name="my_radio" id="1" value="Standard" />
                {rad !== 1 ? <li></li> : <i className="ri-radio-button-line"></i>}
                <div>
                  <p>{t("tran.Standard")}</p>
                  <span>{t("tran.Translation")}</span>
                </div>
              </div>
              <div className={rad !== 2 ? "" : style.check}>
                <input onClick={changeRadio} type="radio" name="my_radio" id="2" value="Standard Plus" />
                {rad !== 2 ? <li></li> : <i className="ri-radio-button-line"></i>}
                <div>
                  <p>{t("tran.StandardPlus")}</p>
                  <span>
                    {t("tran.Translation")} + {t("tran.Native")}
                  </span>
                </div>
              </div>
              <div className={rad !== 3 ? "" : style.check}>
                <input onClick={changeRadio} type="radio" name="my_radio" id="3" value="Premium" />
                {rad !== 3 ? <li></li> : <i className="ri-radio-button-line"></i>}
                <div>
                  <p>{t("tran.Premium")}</p>
                  <span>
                    {t("tran.Translation")} + 1:1 {t("tran.Pro")}
                  </span>
                </div>
              </div>
              <div className={rad !== 4 ? "" : style.check}>
                <input onClick={changeRadio} type="radio" name="my_radio" id="4" value="Premium Plus" />
                {rad !== 4 ? <li></li> : <i className="ri-radio-button-line"></i>}
                <div>
                  <p>{t("tran.PremiumPlus")}</p>
                  <span>
                    {t("tran.Translation")} + 1:1 {t("tran.Pro")} + {t("tran.Native")}
                  </span>
                </div>
              </div>
            </fieldset>
            <fieldset className={style.info}>
              <label htmlFor="">{t("cinfo")}</label>
              <div>
                <div>
                  <label htmlFor="">{t("cop")}</label>
                  <input ref={Cpmpany} name="my_cpmpany" type="text" />
                </div>
                <div>
                  <label htmlFor="">{t("fn")}</label>
                  <input ref={Full} name="my_full" type="text" />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="">{t("login.email")}</label>
                  <input ref={Email} name="my_email" type="Email" />
                </div>
                <div>
                  <label htmlFor="">{t("pn")}</label>
                  <input ref={Phone} name="my_tel" type="tel" />
                </div>
              </div>
            </fieldset>
            <fieldset className={style.add}>
              <label onClick={() => setShowAdd(!showAdd)}>
                {t("tran.Additional")}
                <span>{t("Optional")}</span>
                <i className={showAdd ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
              </label>
              <Expanse show={showAdd}>
                <div>
                  <label htmlFor="">{t("tran.Specialty")}</label>
                  <div>
                    <input name="my_spec" type="text" readOnly value={speci || ""} placeholder="None" />
                    <ul>
                      {specialty.map((item, index) => (
                        <li key={index} id={index} onClick={changeSpecialty}>
                          {item.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <label htmlFor="">{t("tran.Translationguidelines")}</label>
                  <textarea name="my_textarea" id="" cols="30" rows="10" maxLength={500} placeholder={t("tran.Pleaseprovide")}></textarea>
                </div>
              </Expanse>
            </fieldset>
          </fieldset>
          <fieldset className={style.right}>
            <p>{t("tran.Summary")}</p>
            <span>
              {t("tran.Source")}
              <p>
                {words}
                <span>{t("tran.word")}</span>
              </p>
            </span>
            <span>
              {t("tran.Translateto")}
              <p>{tar}</p>
            </span>
            <span>
              {t("tran.Expected")}
              <p>${price}</p>
            </span>
            <span>
              {t("tran.Estimated")}
              <p>313hr 48min–525hr 0min</p>
            </span>
            <i className="ri-arrow-right-line"></i>
            <input type="submit" value={t("tran.Get")} />
          </fieldset>
        </form>
      </div>
      <div className={style.after}></div>
    </>
  );
};

export default Translation;
