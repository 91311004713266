const en_US = {
    'please.input':'Please input',
    'AWorld':"A World Without",
    'Breaking':'Breaking Language Barriers',
    'Pleasecomplete':'Please complete the form below, and we will contact you within 4 hours. (On work hours)',

    "eok":'e',

    'home.widen':'Widen your audience base and convey your brand message to your target market through BLANK’s professional translation and localization services. We deliver premium quality translation and localization solutions. Fast, professional, and affordable.',
    'Our':'Our Professional Services',
    'Reliable':'Reliable High-Quality Translation Service For Your Global Business',
    'Localization':'Localization',
    'Editing':'Editing & Proofreading',
    'Interpretation':'Interpretation',
    'Testimony':'Testimony from clients',
    'Meticulous':'Meticulous Work',
    'Thenature':'The nature of the programs we submitted for translation were urgent projects with very tight deadlines. Despite this, they presented us meticulous, quality work. I strongly recommend BLANK.',
    'SujinPark':'Sujin Park, a TV script writer - MBC',
    'Competitive':'Competitive Price',
    'Having':'Having various options when choosing the translation quality was great. I could request high-quality translation at a competitive price also get proofreading from a native speaker.',
    'Areum':'Areum Kim, a product manager - GS Retail',
    'High-Quality':'High-Quality',
    'Sincewe':'Since we are in the IT industry, the translation we require needs more expertise. We chose a translation expert in IT and Blank manages to deliver the translation on time with such satisfying quality.',
    'SeoyeongLee':'Seoyeong Lee, a project manager - Enitt',
    'Listof':'List of the companies worked with',
    'Getan':'Get an instant quote',
    'Theeasy':'The easy way to get your documents translated fast.',
    'buy':'Buy online in a few clicks.',
    'Instantquote':'Instant quote',
    'Human':'Human-Machine Collaboration as a Must in the Future Translation Industry',
    'Speed':'Speed and accuracy are paramount in the translation industry. What if we adopt AI to increase speed and accuracy, and to maintain a competitive edge?',
    'Blankis':'Blank is an AI-based translation network platform that increases accuracy and efficiency by determining which tasks should be done by human translators or can be replaced by AI.',
    'Artificial':"Artificial intelligence technology is constantly evolving. With the introduction of AI, translation can be automated in high quality. It's time to move on from the translation system where everything from A to Z had to go through by human translators.",
    'Blanksystems':'Blank systems can increase speed, accuracy, and price competitiveness with translating by neural machine translation and post editing by human translators. Clients can quickly receive high-quality results at an affordable price.',
    'Ourcore':"Our core system is that AI helps translators reduce their working hours, then human translators improve the quality of AI's translation results thus coexisting with each other. Blank is the answer to 'how future technologies and humans collaborate'.",
    'Blankaims':'Blank aims to provide high-quality translation services to companies preparing to open new markets globally, global corporate customers and public institutions who want to collaborate with domestic companies and corporations due to growing interest in Korea.',
    'Blankwill':'Blank will break away from the framework of a simple translation platform, help translators improve the structure of the translation process and establish itself as an indispensable Korean professional translation platform in the "K-Contents World Popularization."',
    'Ourserviceprocess':'Our service process',
    'ProjectReview':"1. Project Review",
    'Creationof':'2. Creation of a Glossary',
    'first-order':'3. AI-based first-order translation',
    'Translationby':'4. Translation by a pro translator',
    'accuracycheck':'5. AI-based accuracy check',
    'Proofreading':'6. Proofreading',
    'Verification':'7. Verification',
    'Delivery':'8. Delivery',
    "Please.fill":"Please fill out the empty inputs at first",
    "The.content":"The content has reached the limitation",
    "change":"change",
    
    'cancel':'Cancel',
    'submit':'Submit',
    'select':'Select',
    'english':'English',
    'korean':'Korean',
    'Youcan':'You can select up to 3 specialties.',
    'Preview':'Preview',
    "Optional":'Optional',
    "description":'Translate title and description',
    "I":'I have the source caption file',
    "warn":'Please reselect the document, only support pdf,txt,srt,hwp,doc file format',
    "addurl":'Add Video URL',
    "vurl":'Video URL',
    "piv":"Input Video URL",
    "AboutUs":'About Us',
    "Service":"Service",
    "LanguageService":'Language Service',
    "ProTranslation":'Pro Translation',
    "YouTubeSubtitle":"YouTube Subtitle Translation",
    'Business':'Business',
    'VideoTranslation':'Video Translation',
    'BusinessTranslation':'Business Translation',
    'Support':'Support',
    'BlankSupport':'Blank Support',
    'Terms':'Terms',
    'Privacy':'Privacy Policy',
    'Employment':'Employment Inquiry',
    'BusinessTranslationInquiry':'Business Translation Inquiry',
    'Commerce':'E-Commerce Registration Number',
    'Number':'Business Registration Number',
    'Address':'Address',
    "ceo":"CEO",
    "kim":"Goeun Kim",
    "61472":'(61472) Geumnam-ro, Dong-gu, South Korea',
    'cinfo':'Company info',
    'cop':'Copmpany',
    'fn':'Full Name',
    'pn':'Phone Number',

    "index.home":'Home',
    "index.language":'English',
    "index.service":"Language Service",
    "index.request":"Request Translation",
    'index.apply':'Apply for a Pro Translator',
    'index.sign':'Sign Up',
    'index.our':'Our Service',
    'index.contact':'Contact us',
    'index.contact.inquiry':'Inquiry type',
    'index.contact.inquiry.en':'Enterprise translation',
    'index.contact.inquiry.la':'Language data',
    'index.contact.inquiry.vi':'Video subtitle translation',
    'index.contact.inquiry.api':'API Solution',
    'index.contact.inquiry.pa':'Partnership',
    'index.contact.inquiry.ot':'Other inquiries',
    'index.contact.company':'Company',
    'index.contact.full':'Full Name',
    'index.contact.Email':'Email',
    'index.contact.Phone':'Phone',
    'index.contact.Select':'Select language',
    'index.contact.Source':'Source language',
    'index.contact.Target':'Target language(s)',
    'index.contact.Please':'Please enter your inquiry',
    'index.contact.Sample':'Sample File',
    'index.contact.Sample.top':'Drag and drop or click here to upload',
    'index.contact.Sample.bottom':'May upload up to a maximum of 2M Office, PDF, and SRT files under 5M',

    'login':'Login',
    'login.email':'Email',
    'login.Password':'Password',
    'login.Stay':'Stay signed in',
    'login.Forgot':'Forgot your password?',
    'login.Not':'Not a Blank user yet? Sign up',
    'login.signup':'Sign up',
    'login.signup.withEmail':'Sign up with Email',
    'login.signup.Already':'Already have an account? ',
    'login.email.account':'Account ID',
    'login.email.Native':'Native Language',
    'login.email.By':'By signing up, you agree with the ',
    'login.email.Terms':'Terms ',
    'login.email.and':'and ',
    'login.email.Privacy':'Privacy Policy of Service',


    'apply.before.apply':"Apply for Blank’s Pro Translator",
    'apply.before.Register':"Register and take the online test now",
    'apply.before.You':"You are always welcome to be our Pro Translator.",
    'apply.before.Submit':"Submit resume and take test",
    'apply.before.Criteria':"Criteria",
    'apply.before.In':"In order to become a Pro Translator, you need to have at least one of the following experiences or credentials related to the language pair you have applied.",
    'apply.before.Translating-related':"Translating-related diploma and/or,",
    'apply.before.More':"More than 2 years of professional experience in translation field and/or, ",
    'apply.before.Moretwo':"More than 5 years of overseas experience",
    'apply.before.Resume':"Resume and Sample Translation Test",
    'apply.before.Application':"Application is still available even if you don’t satisfy the above criteria. Fill out the resume and take a test.",
    'apply.before.How':"How to apply",
    'apply.before.1':"1. ‘Register as a Translator’ on Blank",
    'apply.before.2':"2. Fill out the registration form",
    'apply.before.3':"3. Take online test",
    'apply.before.4':"4. Submit the registration form",

    'apply.pro.Next':'Next',
    'apply.pro.Previous':'Previous',
    'apply.pro.Save':'Save',
    'apply.pro.Qualification':'1. Qualification',
    'apply.pro.Key':'2. Key Experiences',
    'apply.pro.Sample':'3. Sample Translation Test',
    'apply.pro.Speciality':'Speciality',
    'apply.pro.Add':'Add',
    'apply.pro.Daily':'Daily Capacity',
    'apply.pro.Full':'Full Time',
    'apply.pro.Part':'Part Time',
    'apply.pro.Words':'Words/day',
    'apply.pro.Contact':'Contact email',
    'apply.pro.Toreceive':'To receive translation requests, please input your primary e-mail address for contact.',
    'apply.pro.Certification':'Certification / Test Scores',
    'apply.pro.Certificate':'Certificate Name',
    'apply.pro.CertificateAuthority':'Certificate Authority',
    'apply.pro.Score':'Score',
    'apply.pro.AddCertification':'Add Certification / Test Scores',
    'apply.pro.Experience':'Experience Overseas',
    'apply.pro.Country':'Country / Region',
    'apply.pro.Period':'Period',
    'apply.pro.Start':'Start time',
    'apply.pro.End':'End time',
    "apply.pro.aEducation":"Education",
    'apply.pro.erro':'Please input valid period in order to submit',
    'apply.pro.AddOverseas':'Add Overseas Experience',
    'apply.pro.School':'School Name',
    'apply.pro.Major':'Major',
    'apply.pro.Minor':'Minor/Double Major',
    'apply.pro.AddSchool':'Add School',
    'apply.pro.Skills':'Skills',
    'apply.pro.Pleaseenter':'Please enter your inquiry',
    'apply.pro.CAT':'e.g. CAT Tools including Studio, Passolo, Wordfast, MemoQ or Software such as InDesign, AutoCAD, Photoshop, MS Word, MS Excel and others.',
    'apply.pro.Project':'Project Name',
    'apply.pro.Client':'Client',
    'apply.pro.Period':'Period',
    'apply.pro.AddExperience':'Add Experience',
    'apply.pro.Original':'Original Sample',
    'apply.pro.Text':'Text here',
    'apply.pro.Please.input':'Please input your translation. Using machine translation is strictly prohibitied.',
    "apply.pro.Politics":"Politics/Marketing",
    "apply.pro.Economics":'Economics/Finance',
    'apply.pro.Law':'Law/Patent',
    'apply.pro.Medical':'Medical Science',
    'apply.pro.Science':'Science',
    'apply.pro.Engineering':'Engineering/Technology',
    'apply.pro.IT':'IT (Computer/Application)',
    'apply.pro.Construction':'Construction/Architecture',
    'apply.pro.Publication':'Publication/Video',
    'apply.pro.Game':'Game',
    'apply.pro.Humanities':'Humanities/Religion',
    'apply.pro.International':'International',
    'apply.pro.Literature':'Literature',
    'apply.pro.Education':'Education/Study Abroad',
    'apply.pro.Culture':'Culture/Art',
    'apply.pro.Beauty':'Beauty/Fashion',
    'apply.pro.Entertainment':'Entertainment/Sports',
    'apply.pro.Life':'Life/Style/Food',
    'apply.pro.Travel':'Travel/History',
    'apply.pro.commerce':'E-commerce',

    'tran.before.Paste':'Paste YouTube URL',
    'tran.before.Pleaseinput':'Please input YouTube URL',
    'tran.before.Input':'Source text for request',
    'tran.before.Pleaseselect':'Please input the source text you wish to request',
    'tran.before.File':'File',
    'tran.before.Subtitle':'Subtitle',
    'tran.before.Drag':'Drag and drop or click here to upload',
    'tran.before.Fileformat':'File format supported: .pdf, .txt, .srt, .hwp, .doc ',
    'tran.before.Click':'Click here to paste YouTube URL',
    'tran.before.The':'The other URLs will cannot be recognized',
    
    'tran.Request':'Request options setting',
    'tran.Request.Select':'Select the detailed options of the translation for request and get the final estimate.',
    'tran.Source':'Source text for request',
    'tran.Reselect':'Reselect method',
    'tran.Re':'Re-import',
    'tran.warning':'If the requesting file includes an image that needs translation, estimate calculation and translation may be difficult.',
    'tran.Selectlanguage':'Select language',
    'tran.Selectservice':'Select service type',
    'tran.Standard':'Standard', 
    'tran.Translation':'Translation',
    'tran.StandardPlus':'Standard Plus',
    'tran.Premium':'Premium',
    'tran.PremiumPlus':'Premium Plus',
    'tran.Native':'Native proofreading',
    'tran.Pro':'Pro editing',
    'tran.Additional':'Additional information',
    'tran.Specialty':'Specialty',
    'tran.None':'None',
    'tran.Translationguidelines':'Translation guidelines',
    'tran.Pleaseprovide':'Please provide the things to note for translation (Cannot request/provide personal contact information)',
    'tran.Summary':'Summary of request',
    'tran.Translateto':'Translate to',
    'tran.Expected':'Expected estimate',
    'tran.Estimated':'Estimated Turnaround Time',
    'tran.Get':'Get final estimate',
    'tran.word':'word(s)',




    'beensent.protran.your':'Your application was submitted',
    'beensent.protran.we':'We will contact via email after thorough review of the application and the test',
    'beensent.tran.your':'Your translation request has been sent successfully.',
    'beensent.tran.we':'We will contact you shortly after reviewing your request. Thank you.',
    'beensent.cont.your':'Your inquiry has been sent successfully.',
    'beensent.cont.we':'We will contact you shortly after reviewing your inquiry. Thank you.',
}
export {
    en_US
}   