import style from "../css/protranslator.scss";
import "../css/index.css";
import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Collapse, Modal, Checkbox, Radio, DatePicker, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import Expanse from "react-expanse";
import moment from "moment";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { DatePickerProps } from "antd";
import emailjs from "emailjs-com";
import apiKeys from "../router/keys";
import Item from "antd/lib/list/Item";
import * as _ from "lodash";
const { TextArea } = Input;

const Protranslator = props => {
  emailjs.init("MgPT_AFf_ROdxwkzr");
  const intl = useIntl();
  const [loadings, setLoadings] = useState(false);
  const t = id => intl.formatMessage({ id });
  const ref1 = useRef([]);
  const [textarea, setTextarea] = useState();
  const [textareatwo, setTextareatwo] = useState();
  const [textareathree, setTextareathree] = useState();
  const [showButton, setShowButton] = useState(false);
  const [dailyRadio, setDailyRadio] = useState("full time");
  const [showDiv, setShowDiv] = useState(1);
  const [finishDiv, setFinishDiv] = useState(0);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [chooseSpecialty, setChooseSpecialty] = useState([]);
  const [AddSpecialty, setAddSpecialty] = useState([]);
  const [modalText, setModalText] = useState("Content of the modal");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDatetwo, setStartDatetwo] = useState();
  const [endDatetwo, setEndDatetwo] = useState();
  const [dateErro, setDateErro] = useState(false);
  const [Certificatenum, setCertificatenum] = useState([{ name: "", authority: "", sorce: "" }]);
  const [cnum, setCnum] = useState(0);
  const [Snum, setSnum] = useState(0);
  const [Enum, setEnum] = useState(0);
  const [Knum, setKnum] = useState(0);
  const [Schoolnum, setSchoolnum] = useState([{ School: "", Major: "", Minor: "" }]);
  const [Experiencenum, setExperiencenum] = useState([{ Country: "", start: "", end: "", sdate: "", edate: "" }]);
  const [keynum, setKeynum] = useState([{ name: "", start: "", end: "", sdate: "", edate: "", client: "", enter: "" }]);
  const [words, setWords] = useState();
  const [email, setEmail] = useState();
  // const [, set] = useState();
  const [Cname, setCname] = useState([]);
  const [Cauth, setCauth] = useState([]);
  const [Csorce, setCsorce] = useState([]);

  const [School, setSchool] = useState([]);
  const [Major, setMajor] = useState([]);
  const [Minor, setMinor] = useState([]);

  const [Country, setCountry] = useState([]);
  const [Estart, setEstart] = useState([]);
  const [Eend, setEend] = useState([]);

  const [Kname, setKname] = useState([]);
  const [Kstart, setKstart] = useState([]);
  const [Kend, setKend] = useState([]);
  const [Kclient, setKclient] = useState([]);
  const [Kenter, setKenter] = useState([]);

  const [spec, setSpec] = useState([]);

  const originSpecialty = [
    { disabled: false, label: t("apply.pro.Politics"), value: t("apply.pro.Politics") },
    { disabled: false, label: t("apply.pro.Economics"), value: t("apply.pro.Economics") },
    { disabled: false, label: t("apply.pro.Law"), value: t("apply.pro.Law") },
    { disabled: false, label: t("apply.pro.Medical"), value: t("apply.pro.Medical") },
    { disabled: false, label: t("apply.pro.Science"), value: t("apply.pro.Science") },
    { disabled: false, label: t("apply.pro.Engineering"), value: t("apply.pro.Engineering") },
    { disabled: false, label: t("apply.pro.IT"), value: t("apply.pro.IT") },
    { disabled: false, label: t("apply.pro.Construction"), value: t("apply.pro.Construction") },
    { disabled: false, label: t("apply.pro.Publication"), value: t("apply.pro.Publication") },
    { disabled: false, label: t("apply.pro.Game"), value: t("apply.pro.Game") },
    { disabled: false, label: t("apply.pro.Humanities"), value: t("apply.pro.Humanities") },
    { disabled: false, label: t("apply.pro.International"), value: t("apply.pro.International") },
    { disabled: false, label: t("apply.pro.Literature"), value: t("apply.pro.Literature") },
    { disabled: false, label: t("apply.pro.Education"), value: t("apply.pro.Education") },
    { disabled: false, label: t("apply.pro.Culture"), value: t("apply.pro.Culture") },
    { disabled: false, label: t("apply.pro.Beauty"), value: t("apply.pro.Beauty") },
    { disabled: false, label: t("apply.pro.Entertainment"), value: t("apply.pro.Entertainment") },
    { disabled: false, label: t("apply.pro.Life"), value: t("apply.pro.Life") },
    { disabled: false, label: t("apply.pro.Travel"), value: t("apply.pro.Travel") },
    { disabled: false, label: t("apply.pro.commerce"), value: t("apply.pro.commerce") },
  ];
  const test = ["1111", "22222", "33333"];
  const monthFormat = "YYYY/MM";
  const [specialty, setSpecialty] = useState(originSpecialty);
  const onChangeTextarea = e => {
    setTextarea(e.target.value);
  };
  const onChangeTextareathree = e => {
    setTextareathree(e.target.value);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      setAddSpecialty(chooseSpecialty);
    }, 0);
  };

  const openAddspecialty = () => {
    let button = chooseSpecialty.length;
    if (button === 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    if (button === 3) {
      let itemsClone = [...specialty];
      itemsClone.forEach(data => {
        data.disabled = true;
      });
      chooseSpecialty.map((value, key) => {
        const index = specialty.findIndex(item => {
          return item.value === value;
        });
        if (index != -1) {
          itemsClone[index].disabled = false;
        }
      });
      setSpecialty(itemsClone);
    } else {
      setSpecialty(
        specialty.map((value, key) => {
          return { ...value, disabled: false };
        })
      );
    }
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setChooseSpecialty(AddSpecialty);
  };
  const onChangeSpecialty = (checkedValues: CheckboxValueType[]) => {
    setChooseSpecialty(checkedValues);
  };

  const onChangeDailyRadio = e => {
    setDailyRadio(e.target.value);
  };

  const success = () => {
    message.success({
      content: t("Please.fill"),
      className: style.iswarning,
      duration: 2,
      style: {
        marginTop: "80px",
      },
    });
  };
  const success2 = () => {
    message.success({
      content: t("The.content"),
      className: style.iswarning,
      duration: 2,
      style: {
        marginTop: "80px",
      },
    });
  };
  const succ = _.throttle(success, 4000);
  const succ2 = _.throttle(success2, 4000);

  const goNext = e => {
    if (showDiv === 1) {
      if (AddSpecialty.length === 0 || words === "" || email === "" || textarea === "") {
        succ();
        return;
      } else {
        Certificatenum.map((item, i) => {
          if (item.name === "" || item.authority === "" || item.sorce === "") {
            succ();
            return;
          } else {
            Experiencenum.map((num, j) => {
              if (num.Country === "" || num.start === "" || num.end === "" || num.sdate > num.edate) {
                succ();
                return;
              } else {
                Schoolnum.map((value, k) => {
                  if (value.School === "" || value.Major === "" || value.Minor === "") {
                    succ();
                    return;
                  } else {
                    const index = showDiv + 1;
                    const finish = finishDiv + 1;
                    setShowDiv(index);
                    setFinishDiv(finish);
                  }
                });
              }
            });
          }
        });
      }
    } else if (showDiv === 2) {
      keynum.map((item, index) => {
        if (item.name === "" || item.enter === "" || item.start === "" || item.end === "" || item.client === "" || item.sdate > item.edate) {
          succ();
          return;
        } else {
          const index = showDiv + 1;
          const finish = finishDiv + 1;
          setShowDiv(index);
          setFinishDiv(finish);
        }
      });
    } else if (showDiv === 3) {
      if (textareathree === "") {
        succ();
        return;
      } else {
        setLoadings(true);
        setCname([]);
        setCauth([]);
        setCsorce([]);
        setSchool([]);
        setMajor([]);
        setMinor([]);
        setCountry([]);
        setEstart([]);
        setEend([]);
        setKname([]);
        setKstart([]);
        setKend([]);
        setKclient([]);
        setKenter([]);
        Certificatenum.map((item, index) => {
          const i = index + 1;
          const C = Cname;
          const Ca = Cauth;
          const Cs = Csorce;
          Cs.push("<p>" + i + "." + item.sorce + "</p>");
          Ca.push("<p>" + i + "." + item.authority + "</p>");
          C.push("<p>" + i + "." + item.name + "</p>");
          setCname(C);
          setCauth(Ca);
          setCsorce(Cs);
        });
        // "<p>" +  + "</p>"
        Schoolnum.map((item, index) => {
          const i = index + 1;
          const C = School;
          const Ca = Major;
          const Cs = Minor;
          C.push("<p>" + i + "." + item.School + "</p>");
          Ca.push("<p>" + i + "." + item.Major + "</p>");
          Cs.push("<p>" + i + "." + item.Minor + "</p>");
          setSchool(C);
          setMajor(Ca);
          setMinor(Cs);
        });
        Experiencenum.map((item, index) => {
          const i = index + 1;
          const C = Country;
          const Ca = Estart;
          const Cs = Eend;
          C.push("<p>" + i + "." + item.Country + "</p>");
          Ca.push("<p>" + item.start + "</p>");
          Cs.push("<p>" + item.end + "</p>");
          setCountry(C);
          setEstart(Ca);
          setEend(Cs);
        });
        keynum.map((item, index) => {
          const i = index + 1;
          const C = Kname;
          const Ca = Kstart;
          const Cs = Kend;
          const kc = Kclient;
          const ke = Kenter;
          C.push("<p>" + i + "." + item.name + "</p>");
          Ca.push("<p>" + item.start + "</p>");
          Cs.push("<p>" + item.end + "</p>");
          kc.push("<p>" + i + "." + item.client + "</p>");
          ke.push("<p>" + i + "." + item.enter + "</p>");
          setKname(C);
          setKstart(Ca);
          setKend(Cs);
          setKclient(kc);
          setKenter(ke);
        });
        spec.map((item, index) => {
          const i = index + 1;
          const sp = spec;
          sp.push("<p>" + i + "." + item + "</p>");
          setSpec(sp);
        });
        emailjs
          .send("service_rmh93g9", "template_v3qy3w2", {
            my_html:
              "<h1>Apply for a pro translator</h1> <div> <div> <h2>Speciality</h2>" +
              spec +
              "</div> <div> <h2>Daily Capacity</h2> <span>" +
              dailyRadio +
              "&</span> <span>" +
              words +
              " Words/day</span> </div> <div> <h2>Contact email</h2> <span>" +
              email +
              "</span> </div> <div> <h2>Certification / Test Scores</h2> <div style='display: flex'> <div style='margin-right: 50px'> <h4>Certificate Name</h4> <div>" +
              Cname +
              "</div> </div> <div style='margin-right: 50px'> <h4>Certificate Authority</h4> <div>" +
              Cauth +
              "</div> </div> <div style='margin-right: 50px'> <h4>Score</h4> <div>" +
              Csorce +
              "</div> </div> </div> </div> <div> <h2>Experience Overseas</h2> <div style='display: flex'> <div style='margin-right: 50px'> <h4>Country / Region</h4> <div>" +
              Country +
              "</div> </div> <div style='display: flex;flex-direction: row;'> <div style='margin-right: 50px'> <h4>start time</h4> <div'>" +
              Estart +
              "</div> </div> <div style='margin-right: 50px'> <h4>End time</h4> <div>" +
              Eend +
              "</div> </div> </div> </div> </div> <div> <h2>Education</h2> <div style='display: flex'> <div style='margin-right: 50px'> <h4>School Name</h4> <div>" +
              School +
              "</div> </div> <div style='margin-right: 50px'> <h4>Major</h4> <div>" +
              Major +
              "</div> </div> <div style='margin-right: 50px'> <h4>Minor/Double Major</h4> <div>" +
              Minor +
              "</div> </div> </div> </div> <div> <h2>Skills</h2> <div style='max-width: 500px'>" +
              textarea +
              "</div> </div> <div> <h1>Key Experiences</h1> <div> <h2>Key Experiences</h2> <div style='display: flex'> <div style='margin-right: 50px'> <h4>Project Name</h4> <div>" +
              Kname +
              "</div> </div> <div style='margin-right: 50px'> <h4>Client</h4> <div>" +
              Kclient +
              "</div> </div> <div style='margin-right: 50px'> <h4>start time</h4> <div>" +
              Estart +
              "</div> </div> <div style='margin-right: 50px'> <h4>End time</h4> <div>" +
              Eend +
              "</div> </div> <div style='margin-right: 50px'> <h4>Description</h4> <div style=' max-width: 500px'>" +
              Kenter +
              "</div> </div> </div> </div> </div> <div> <h1>Sample Translation Test</h1> <span>" +
              textareathree +
              "</span> </div> </div>",
          })
          .then(
            result => {
              setLoadings(false);
              props.history.push({
                pathname: "/beensent",
                query: {
                  id: "protran",
                },
              });
            },
            error => {
              alert("Sent failed");
            }
          );
      }
    }
  };

  const goBack = e => {
    if (1 < showDiv < 3) {
      const index = showDiv - 1;
      const finish = finishDiv - 1;
      setShowDiv(index);
      setFinishDiv(finish);
    } else {
      setShowDiv(1);
      setFinishDiv(0);
    }
  };

  const onAdd = e => {
    const id = e.target.id;
    if (id === "Certifi") {
      const k = cnum + 1;
      Certificatenum.map((item, index) => {
        const num = [...Certificatenum];
        if (index < 9) {
          const i = k - 1;
          if (num[i].name === "" || num[i].authority === "" || num[i].sorce === "") {
            succ();
            setCnum(cnum);
          } else {
            setCertificatenum([...Certificatenum, { name: "", authority: "", sorce: "" }]);
            setCnum(k);
          }
        } else {
          setCertificatenum(Certificatenum);
          succ2();
          setCnum(cnum);
        }
      });
    } else if (id === "Overseas") {
      const k = Enum + 1;
      Experiencenum.map((item, index) => {
        const num = [...Experiencenum];
        if (index < 9) {
          const i = k - 1;
          if (num[i].Country === "" || num[i].start === "" || num[i].end === "") {
            succ();
            setEnum(Enum);
          } else {
            if (num[i].sdate > num[i].edate) {
              return;
            } else {
              setExperiencenum([...Experiencenum, { Country: "", start: "", end: "", sdate: "", edate: "" }]);
              setEnum(k);
            }
          }
        }
      });
    } else if (id === "School") {
      const k = Snum + 1;
      Schoolnum.map((item, index) => {
        const num = [...Schoolnum];
        if (index < 9) {
          const i = Snum;
          if (num[i].School === undefined || num[i].Minor === undefined || num[i].Major === undefined) {
            succ();
            setSnum(Snum);
          } else {
            setSchoolnum([...Schoolnum, { School: "", Major: "", Minor: "" }]);
            setSnum(k);
          }
        } else {
          setSchoolnum(Schoolnum);
          succ2();
          setSnum(Snum);
        }
      });
    } else if (id === "key") {
      const k = Knum + 1;
      keynum.map((item, index) => {
        const num = [...keynum];
        if (index < 9) {
          const i = Knum;
          if (num[i].name === "" || num[i].client === "" || num[i].start === "" || num[i].end === "" || num[i].enter === "" || num[i].sdate > num[i].edate) {
            succ();
            setKnum(Knum);
          } else {
            setKeynum([...keynum, { name: "", start: "", end: "", sdate: "", edate: "", client: "", enter: "" }]);
            setKnum(k);
          }
        } else {
          setKeynum(keynum);
          succ2();
          setKnum(Knum);
        }
      });
    }
  };

  const goHome = () => {
    props.history.push("/");
  };

  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const openErro = () => {
    succ();
  };
  const openWarning = () => {
    succ2();
  };

  const changename = e => {
    const id = e.target.id;
    setCertificatenum(Certificatenum =>
      Certificatenum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            name: e.target.value,
          };
        }
      })
    );
  };
  const changeauthor = e => {
    const id = e.target.id;
    setCertificatenum(Certificatenum =>
      Certificatenum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            authority: e.target.value,
          };
        }
      })
    );
  };
  const changescore = e => {
    const id = e.target.id;
    setCertificatenum(Certificatenum =>
      Certificatenum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            sorce: e.target.value,
          };
        }
      })
    );
  };
  const changeSchool = e => {
    const id = e.target.id;
    setSchoolnum(Schoolnum =>
      Schoolnum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            School: e.target.value,
          };
        }
      })
    );
  };
  const changeMajor = e => {
    const id = e.target.id;
    setSchoolnum(Schoolnum =>
      Schoolnum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            Major: e.target.value,
          };
        }
      })
    );
  };
  const changeMinor = e => {
    const id = e.target.id;
    setSchoolnum(Schoolnum =>
      Schoolnum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            Minor: e.target.value,
          };
        }
      })
    );
  };
  const changeCountry = e => {
    const id = e.target.id;
    setExperiencenum(Experiencenum =>
      Experiencenum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            Country: e.target.value,
          };
        }
      })
    );
  };
  const changeStartDate = (e, index) => {
    const date = new Date(e._d);
    const da = date.getFullYear() + "/" + (date.getMonth() + 1);
    setExperiencenum(Experiencenum =>
      Experiencenum.map((item, key) => {
        if (key != index) {
          return item;
        } else {
          return {
            ...item,
            start: da,
            sdate: date,
          };
        }
      })
    );
  };
  const changeEndDate = (e, index) => {
    const date = new Date(e._d);
    const da = date.getFullYear() + "/" + (date.getMonth() + 1);
    setExperiencenum(Experiencenum =>
      Experiencenum.map((item, key) => {
        if (key != index) {
          return item;
        } else {
          return {
            ...item,
            end: da,
            edate: date,
          };
        }
      })
    );
  };
  const changeProject = e => {
    const id = e.target.id;
    setKeynum(keynum =>
      keynum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            name: e.target.value,
          };
        }
      })
    );
  };
  const changeClient = e => {
    const id = e.target.id;
    setKeynum(keynum =>
      keynum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            client: e.target.value,
          };
        }
      })
    );
  };
  const onChangeTextareatwo = (e, index) => {
    const value = e.target.value;
    const id = e.target.id;
    setKeynum(keynum =>
      keynum.map((item, index) => {
        if (index != id) {
          return item;
        } else {
          return {
            ...item,
            enter: e.target.value,
          };
        }
      })
    );
  };
  const changeStartDatetwo = (e, index) => {
    const date = new Date(e._d);
    const da = date.getFullYear() + "/" + (date.getMonth() + 1);
    setKeynum(keynum =>
      keynum.map((item, key) => {
        if (key != index) {
          return item;
        } else {
          return {
            ...item,
            start: da,
            sdate: date,
          };
        }
      })
    );
  };
  const changeEndDatetwo = (e, index) => {
    const date = new Date(e._d);
    const da = date.getFullYear() + "/" + (date.getMonth() + 1);
    setKeynum(keynum =>
      keynum.map((item, key) => {
        if (key != index) {
          return item;
        } else {
          return {
            ...item,
            end: da,
            edate: date,
          };
        }
      })
    );
  };

  useEffect(() => {
    let button = chooseSpecialty.length;
    if (button === 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    if (button === 3) {
      let itemsClone = [...specialty];
      itemsClone.forEach(data => {
        data.disabled = true;
      });
      chooseSpecialty.map((value, key) => {
        const index = specialty.findIndex(item => {
          return item.value === value;
        });
        if (index != -1) {
          itemsClone[index].disabled = false;
        }
      });
      setSpecialty(itemsClone);
    } else {
      setSpecialty(
        specialty.map((value, key) => {
          return { ...value, disabled: false };
        })
      );
    }
  }, [chooseSpecialty]);
  return (
    <>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.icon} onClick={goHome}>
            <i className="ri-close-line"></i>
          </div>
          <span>{t("index.apply")}</span>
        </div>
        <div className={style.right}>
          <Button loading={loadings} type="primary" className={style.one} onClick={goNext}>
            {t("apply.pro.Next")}
          </Button>
          <Button className={style.two} onClick={goBack}>
            {t("apply.pro.Previous")}
          </Button>
        </div>
      </div>
      <div className={style.process}>
        <div className={finishDiv >= 1 ? style.divfinish : showDiv === 1 ? style.divclick : ""}>
          {finishDiv >= 1 ? <i className="ri-checkbox-circle-fill"></i> : <i className={showDiv === 1 ? "ri-radio-button-line" : "ri-radio-button-fill"}></i>}
          <span>{t("apply.pro.Qualification")}</span>
        </div>
        <div className={finishDiv >= 2 ? style.divfinish : showDiv === 2 ? style.divclick : ""}>
          {finishDiv >= 2 ? <i className="ri-checkbox-circle-fill"></i> : <i className={showDiv === 2 ? "ri-radio-button-line" : "ri-radio-button-fill"}></i>}
          <span>{t("apply.pro.Key")}</span>
        </div>
        <div className={finishDiv >= 3 ? style.divfinish : showDiv === 3 ? style.divclick : ""}>
          {finishDiv >= 3 ? <i className="ri-checkbox-circle-fill"></i> : <i className={showDiv === 3 ? "ri-radio-button-line" : "ri-radio-button-fill"}></i>}
          <span>{t("apply.pro.Sample")}</span>
        </div>
      </div>
      <div className={style.box} style={{ display: showDiv === 1 ? "block" : "none" }}>
        <div className={style.title}>
          <div className={style.top}>
            <span>{t("apply.pro.Qualification")}</span>
          </div>
          <div className={style.body}>
            <div className={style.speciality}>
              <span>{t("apply.pro.Speciality")}</span>
              <p onClick={openAddspecialty}>
                <i className="ri-add-line"></i>
                {t("apply.pro.Add")}
              </p>
              <ul>
                {AddSpecialty.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
                {AddSpecialty != "" ? <p onClick={openAddspecialty}>{t("change")}</p> : ""}
              </ul>
              <Modal
                getContainer={false}
                className={style.modal}
                title={t("tran.Specialty")}
                open={open}
                confirmLoading={confirmLoading}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button key="Cancel" onClick={handleCancel} className={style.cancel}>
                    {t("cancel")}
                  </Button>,
                  <Button key="Select" type="primary" loading={confirmLoading} onClick={handleOk} className={!showButton ? style.select : style.noselect} disabled={showButton}>
                    {t("select")}
                  </Button>,
                ]}
              >
                <p>{t("Youcan")}</p>
                <Checkbox.Group defaultValue={AddSpecialty} className={style.checkbox} options={specialty} onChange={onChangeSpecialty} />
              </Modal>
            </div>
            <div>
              <span>{t("apply.pro.Daily")}</span>
              <div className={style.radio}>
                <Radio.Group onChange={onChangeDailyRadio} value={dailyRadio}>
                  <Radio value="full time">{t("apply.pro.Full")}</Radio>
                  <Radio value="part time">{t("apply.pro.Part")}</Radio>
                </Radio.Group>
                <Input style={{ backgroundColor: "#fff" }} type="number" onChange={e => setWords(e.target.value)} />
                <span>{t("apply.pro.Words")}</span>
              </div>
            </div>
            <div>
              <span style={{ display: "block" }}>{t("apply.pro.Contact")}</span>
              <Input type="email" onChange={e => setEmail(e.target.value)} placeholder={t("please.input")} />
              <div className={style.inputbottom}>{t("apply.pro.Toreceive")}</div>
            </div>
            <div className={style.threeinput}>
              <span>{t("apply.pro.Certification")}</span>
              {Certificatenum.map((item, index) => (
                <div key={index}>
                  {index >= 1 ? <div className={style.bordertop}></div> : ""}
                  <div className={style.input}>
                    <div>
                      <p>{t("apply.pro.Certificate")}</p>
                      <Input value={item.name} onChange={changename} placeholder={t("please.input")} id={index} />
                    </div>
                    <div>
                      <p>{t("apply.pro.CertificateAuthority")}</p>
                      <Input value={item.authority} onChange={changeauthor} placeholder={t("please.input")} id={index} />
                    </div>
                    <div>
                      <p style={{ display: "flex", justifyContent: "space-between" }}>
                        {t("apply.pro.Score")}{" "}
                        {index >= 1 ? (
                          <button
                            onClick={e => {
                              const arr = [...Certificatenum];
                              const i = index - 1;
                              arr.splice(index, 1);
                              setCnum(i);
                              setCertificatenum(arr);
                            }}
                            id="111"
                          >
                            <i className="ri-close-line"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                      <Input value={item.sorce} onChange={changescore} placeholder={t("please.input")} id={index} />
                    </div>
                  </div>
                </div>
              ))}
              <p id="Certifi" onClick={onAdd}>
                <i className="ri-add-line"></i>
                {t("apply.pro.AddCertification")}
              </p>
            </div>
            <div className={style.threeinput}>
              <span>{t("apply.pro.Experience")}</span>
              {Experiencenum.map((item, index) => (
                <div key={index}>
                  {index >= 1 ? <div className={style.bordertop} style={{ marginTop: 30 }}></div> : ""}
                  <div id={index} key={index} className={style.input}>
                    <div>
                      <p>{t("apply.pro.Country")}</p>
                      <Input onChange={changeCountry} value={item.Country} placeholder={t("please.input")} id={index} />
                    </div>
                    <div className={style.period}>
                      <p style={{ display: "flex", justifyContent: "space-between" }}>
                        {t("apply.pro.Period")}
                        {index >= 1 ? (
                          <button
                            onClick={e => {
                              const arr = [...Experiencenum];
                              const i = index - 1;
                              arr.splice(index, 1);
                              setEnum(i);
                              setExperiencenum(arr);
                            }}
                          >
                            <i className="ri-close-line"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className={item.sdate > item.edate ? style.erro : ""}>
                        <DatePicker defaultValue={item.sdate} inputReadOnly={true} format={monthFormat} picker="month" placeholder={t("apply.pro.Start")} bordered={false} className={style.date} onChange={e => changeStartDate(e, index)} />
                        <p>–</p>
                        <DatePicker defaultValue={item.edate} inputReadOnly={true} format={monthFormat} picker="month" placeholder={t("apply.pro.End")} bordered={false} className={style.date} onChange={e => changeEndDate(e, index)} />
                      </div>
                      <div style={{ display: item.sdate > item.edate ? "block" : "none" }} className={style.inputbottom}>
                        {t("apply.pro.erro")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <p id="Overseas" onClick={onAdd}>
                <i className="ri-add-line"></i>
                {t("apply.pro.AddOverseas")}
              </p>
            </div>
            <div className={style.threeinput}>
              <span>{t("apply.pro.aEducation")}</span>
              {Schoolnum.map((item, index) => (
                <div key={index}>
                  {index >= 1 ? <div className={style.bordertop}></div> : ""}
                  <div key={index} id={index} className={style.input}>
                    <div>
                      <p>{t("apply.pro.School")}</p>
                      <Input value={item.School} onChange={changeSchool} placeholder={t("please.input")} id={index} />
                    </div>
                    <div>
                      <p>{t("apply.pro.Major")}</p>
                      <Input value={item.Major} onChange={changeMajor} placeholder={t("please.input")} id={index} />
                    </div>
                    <div>
                      <p style={{ display: "flex", justifyContent: "space-between" }}>
                        {t("apply.pro.Minor")}
                        {index >= 1 ? (
                          <button
                            onClick={e => {
                              const arr = [...Schoolnum];
                              const i = index - 1;
                              arr.splice(index, 1);
                              setSnum(i);
                              setSchoolnum(arr);
                            }}
                            id="111"
                          >
                            <i className="ri-close-line"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                      <Input value={item.Minor} onChange={changeMinor} placeholder={t("please.input")} id={index} />
                    </div>
                  </div>
                </div>
              ))}
              <p id="School" onClick={onAdd}>
                <i className="ri-add-line"></i>
                {t("apply.pro.AddSchool")}
              </p>
            </div>
            <div>
              <span>{t("apply.pro.Skills")}</span>
              <TextArea className={style.textarea} placeholder={t("apply.pro.Pleaseenter")} showCount maxLength={300} onChange={onChangeTextarea} autoSize="ture" />
              <div className={style.inputbottom}>{t("apply.pro.CAT")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.box} style={{ display: showDiv === 2 ? "block" : "none" }}>
        <div className={style.title}>
          <div className={style.top}>
            <span>{t("apply.pro.Key")}</span>
          </div>
          <div className={style.body}>
            {keynum.map((item, index) => (
              <div key={index}>
                {index >= 1 ? <div className={style.bordertop}></div> : ""}
                <div className={style.threeinput}>
                  <span style={{ display: "flex", justifyContent: "space-between" }}>
                    {t("apply.pro.Key")}
                    {index >= 1 ? (
                      <button
                        onClick={e => {
                          const arr = [...keynum];
                          const i = index - 1;
                          arr.splice(index, 1);
                          setKnum(i);
                          setKeynum(arr);
                        }}
                        id="111"
                      >
                        <i className="ri-close-line"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                  <div className={style.input}>
                    <div>
                      <p>{t("apply.pro.Project")}</p>
                      <Input id={index} value={item.name} onChange={changeProject} placeholder={t("please.input")} />
                    </div>
                    <div>
                      <p>{t("apply.pro.Client")}</p>
                      <Input id={index} value={item.client} onChange={changeClient} placeholder={t("please.input")} />
                    </div>
                  </div>
                </div>
                <div className={style.threeinput}>
                  <div className={style.input}>
                    <div className={style.period}>
                      <p>{t("apply.pro.Period")}</p>
                      <div className={item.sdate > item.edate ? style.erro : ""}>
                        <DatePicker inputReadOnly={true} format={monthFormat} picker="month" placeholder={t("apply.pro.Start")} bordered={false} className={style.date} onChange={e => changeStartDatetwo(e, index)} />
                        <p>–</p>
                        <DatePicker inputReadOnly={true} format={monthFormat} picker="month" placeholder={t("apply.pro.End")} bordered={false} className={style.date} onChange={e => changeEndDatetwo(e, index)} />
                      </div>
                      <div style={{ display: item.sdate > item.edate ? "block" : "none" }} className={style.inputbottom}>
                        {t("apply.pro.erro")}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TextArea id={index} className={style.textareatwo} placeholder={t("apply.pro.Pleaseenter")} showCount maxLength={300} onChange={e => onChangeTextareatwo(e, index)} autoSize="ture" />
                </div>
              </div>
            ))}
            <p className={style.pa} id="key" onClick={onAdd}>
              <i className="ri-add-line"></i>
              {t("apply.pro.AddExperience")}
            </p>
          </div>
        </div>
      </div>
      <div className={style.box} style={{ display: showDiv === 3 ? "block" : "none" }}>
        <div className={style.title}>
          <div className={style.top}>
            <span>{t("apply.pro.Sample")}</span>
          </div>
          <div className={style.body}>
            <div>
              <span>{t("apply.pro.Original")}</span>
              <div className={style.threeinput}>
                <div className={style.lasttext}>{t("apply.pro.Text")}</div>
                <TextArea className={style.textareathree} placeholder={t("apply.pro.Please.input")} showCount maxLength={300} onChange={onChangeTextareathree} autoSize="ture" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.after}></div>
    </>
  );
};

export default Protranslator;
