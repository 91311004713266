import Index from '../pages/index';
// import Login from '../pages/login';
import Protranslator from '../pages/protranslator'
import Translation from '../pages/translation';
import Home from '../pages/home';
import Beensent from '../pages/beensent';
import Beforeprotranslator from '../pages/beforeprotranslator';
import Homeour from '../pages/homeour';
import Mobileheader from '../pages/mobileheader';

const routes = [
    {
        path:'/protranslator',
        component:Protranslator
    },
    {
        path:'/',
        component:Index,
        routes:[ 
            {
                path:"/home",
                component:Home,
            },
            {
                path:"/mobileheader",
                component:Mobileheader,
            },
            {
                path:"/homeour",
                component:Homeour,
            },
            {
                path:'/beforeprotranslator',
                component:Beforeprotranslator
            },
            {
                path:'/translation',
                component:Translation,
            },
            {
                path:'/beensent',
                component:Beensent,
            },
        ]
    },
    
    
]


export default routes