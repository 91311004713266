import style from "../css/homeour.scss";
import img72 from "../images/image72.png";
import img75 from "../images/image75.jpg";
import translation from "../images/Translation.png";
import React, { useEffect, useState } from "react";
import Expanse from "react-expanse";
import { useIntl } from "react-intl";

const Homeour = () => {
  const [blank, setBlank] = useState(false);
  const [service, setService] = useState(false);
  const [business, setBusiness] = useState(false);
  const [support, setSupport] = useState(false);
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const [World, setWorld] = useState(false);
  const arr = [
    { img: "ri-profile-line", value: t("ProjectReview") },
    { img: "ri-book-2-line", value: t("Creationof") },
    { img: "ri-share-forward-box-fill", value: t("first-order") },
    { img: "ri-user-2-line", value: t("Translationby") },
    { img: "ri-terminal-box-line", value: t("accuracycheck") },
    { img: "ri-list-check-2", value: t("Proofreading") },
    { img: "ri-checkbox-multiple-line", value: t("Verification") },
    { img: "ri-share-forward-box-fill", value: t("Delivery") },
  ];
  useEffect(() => {
    setWorld(!World);
    if (t("eok") === "e") {
      setWorld(true);
    } else if (t("eok") === "k") {
      setWorld(false);
    }
  }, [t("eok")]);
  return (
    <>
      <div>
        <div className={style.ourbox}>
          <div>
            <p>{t("Human")}</p>
            <div>
              <img src={img72} alt="" />
              <div>
                <span>{t("Speed")}</span>
                {World ? "" : <span> 블랭크는 문맥을 파악하지 않은 단순한 일대일 단어 치환 번역에서 벗어나 타 문화의 이해를 기반으로 한 현지화 소통 번역 서비스를 제공합니다.</span>}
                <span>{t("Blankis")}</span>
                <span>{t("Artificial")}</span>
              </div>
            </div>
            <div className={style.bottomimg}>
              <div className={style.bottom}>
                <span>{t("Blanksystems")}</span>
                <span>{t("Ourcore")}</span>
                <span>{t("Blankaims")}</span>
                <span>{t("Blankwill")}</span>
              </div>
              <img src={img75} alt="" />
            </div>
          </div>
        </div>
        <div className={style.process}>
          <p>{t("Ourserviceprocess")}</p>
          <ul>
            {arr.map((item, key) =>
              key === 2 ? (
                <li key={key}>
                  <div>
                    <img src={translation} alt="" />
                  </div>
                  <p>{item.value}</p>
                </li>
              ) : (
                <li key={key}>
                  <div>
                    <i className={item.img}></i>
                  </div>
                  <p>{item.value}</p>
                </li>
              )
            )}
          </ul>
        </div>
        <div className={style.foot}>
          <div className={style.mobiletop}>
            <div onClick={() => setBlank(!blank)}>
              Blank<i className={!blank ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={blank}>
              <div className={style.topexpanse}>
                <span>{t("AboutUs")}</span>
              </div>
            </Expanse>
            <div onClick={() => setService(!service)}>
              {t("Service")}
              <i className={!service ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={service}>
              <div className={style.topexpanse}>
                <span>{t("LanguageService")}</span>
                <span>{t("ProTranslation")}</span>
                <span>{t("YouTubeSubtitle")}</span>
              </div>
            </Expanse>
            <div onClick={() => setBusiness(!business)}>
              {t("Business")}
              <i className={!business ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={business}>
              <div className={style.topexpanse}>
                <span>{t("VideoTranslation")}</span>
                <span>{t("BusinessTranslation")}</span>
              </div>
            </Expanse>
            <div onClick={() => setSupport(!support)}>
              {t("Support")}
              <i className={!support ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={support}>
              <div className={style.topexpanse}>
                <span>{t("BlankSupport")}</span>
                <span>{t("Terms")}</span>
                <span>{t("Privacy")}</span>
              </div>
            </Expanse>
          </div>
          <div className={style.top}>
            <div>
              <p>Blank</p>
              <span>{t("AboutUs")}</span>
            </div>
            <div>
              <p>{t("Service")}</p>
              <span>{t("LanguageService")}</span>
              <span>{t("ProTranslation")}</span>
              <span>{t("YouTubeSubtitle")}</span>
            </div>
            <div>
              <p>{t("Business")}</p>
              <span>{t("VideoTranslation")}</span>
              <span>{t("BusinessTranslation")}</span>
            </div>
            <div>
              <p>{t("Support")}</p>
              <span>{t("BlankSupport")}</span>
              <span>{t("Terms")}</span>
              <span>{t("Privacy")}</span>
            </div>
          </div>
          <div className={style.bottom}>
            <div>
              <p>{t("Employment")}</p>
              <span>blank.translate@gmail.com</span>
            </div>
            <div>
              <p>{t("ceo")}</p>
              <span>{t("kim")}</span>
            </div>
            <div>
              <p>{t("BusinessTranslationInquiry")}</p>
              <span>blank.translate@gmail.com</span>
            </div>
            <div>
              <p>{t("Commerce")}</p>
              <span>2021-GwangjuGwangsan-0287</span>
            </div>
            <div>
              <p>{t("Number")}</p>
              <span>236-38-00655</span>
            </div>
            <div>
              <p>{t("Address")}</p>
              <span>{t("61472")}</span>
            </div>
          </div>
          <span>©2024 Beyondary. All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

export default Homeour;
