import React,{useState,useEffect} from 'react';  
import ReactDOM from 'react-dom';
import 'remixicon/fonts/remixicon.css'
import 'antd/dist/antd.min.css';

import {
  BrowserRouter as Router,Route
} from 'react-router-dom'

import { renderRoutes } from 'react-router-config'
import './css/style.scss'
import routes from './router/index'
import {IntlProvider} from 'react-intl'
import { en_US } from './locale/en_US';
import { ko_KR } from './locale/ko_KR';
import store from './router/store';
import { Provider ,connect} from 'react-redux';

const messages = {
  'en': en_US,
  'ko': ko_KR,
}


const App = () => {
  const [index, setIndex] = useState("en");
  store.subscribe(()=>{
      setIndex(store.getState().language)
  })
  return (
    <Provider store={store}>
    <div>
      <IntlProvider locale={index} messages={messages[index]}>
      { renderRoutes(routes) }
      </IntlProvider>
    </div>
    </Provider>
  );
}




ReactDOM.render(
  
  <Provider store={store}>
    <Router>
      <App/> 
    </Router>
  </Provider>
  ,
  document.getElementById('root')
);
