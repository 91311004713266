const ko_KR = {
    'please.input':'입력하세요',
    'AWorld':"언어장벽 없는 세상을 위한한국어 특화 전문 번역서비스",
    'Breaking':'Breaking Language Barriers',
    'Pleasecomplete':'하단의 양식을 작성 후 제출하시면 4시간 이내로 연락드립니다. (영업일 기준)',

    "eok":'k',

    'home.widen':"블랭크의 전문 번역 및 현지화 서비스를 통해 전세계로 고객층을 확장하세요. '한국어 특화 전문 번역서비스'로 목표 시장에 정확한 브랜드 메시지를 전달합니다. 신속하고 전문적인 번역 서비스를 합리적인 가격에 이용하세요. 블랭크는 국내 기업의 해외 판로개척 및 글로벌 사업에 늘 함께하겠습니다.",
    'Our':'핵심 서비스',
    'Reliable':'글로벌 비즈니스를 위한 고품질 맞춤형 서비스를 제공합니다.',
    'Localization':'현지화',
    'Editing':'수정 & 검수',
    'Interpretation':'통역',
    'Testimony':'고객사 후기',
    'Meticulous':'세심한 작업',
    'Thenature':"의뢰한 프로그램 특성상 의뢰드릴 때마다 급박한 마감기한의 작업물이 많았는데, 매번 시간 내 빠르고 꼼꼼한 결과물을 주셔서 정말 만족스러웠습니다. 신속한 결과물과 높은 퀄리티를 동시에 원하신다면, 블랭크를 강력 추천드립니다,",
    'SujinPark':'박◯진, 구성작가 - MBC',
    'Competitive':'합리적인 가격',
    'Having':'간단히 내용만 확인하고 싶은 번역도 있고, 정확해야하는 결과물도 있었기 때문에 원하는 번역 퀄리티에 따라 옵션을 선택할 수 있어서 좋았습니다. 원어민 검수가 포함된 옵션임에도 합리적인 가격으로 고품질의 번역을 요청할 수 있어 만족스러웠습니다.',
    'Areum':'김◯름, MD - GS Retail',
    'High-Quality':'전문성있는 번역',
    'Sincewe':'IT기업의 특성상 전문성을 필요로 하는 어렵고 복잡한 단어가 많았음에도 불구하고, 블랭크에서는 IT분야의 전문 번역가님과의 매칭으로 매끄럽고 정확한 결과물을 주셨습니다. 단순한 단어 번역이 아닌 해당 업계의 배경지식이 담긴 결과물이라 좋았습니다.',
    'SeoyeongLee':'이◯영, 프로덕트 매니저 - Enitt',
    'Listof':'고객사 · 파트너사',
    'Getan':'견적이 궁금하시나요?',
    'Theeasy':'단 한번의 클릭으로 번역 견적서를 받아보세요.',
    'buy':'간단한 파일 업로드로 견적을 바로 확인할 수 있습니다.',
    'Instantquote':'견적 받아보기',
    'Human':'번역은 단순한 일대일의 단어 치환이 아닌 타 문화의 이해를 기반으로 한 소통입니다.',
    'Speed':"번역 산업에서 언어 전달의 정확성은 그 무엇보다 중요합니다. 그렇다면 ‘번역 정확성'은 어떻게 측정할까요?",
    'Blankis':"2018년 개봉된 영화 <어벤져스3>에서는 'We're in the endgame now (우리는 지금 최종단계에 진입했어)'라는 문장을 '우리는 가망이 없다'라고 오역해 큰 화제가 된 사례가 있었습니다. 문맥을 파악하지 못한 단순 일대일 단어 치환은 시청자 및 독자로 하여금 오해를 불러 일으키는 치명적인 실수입니다.",
    'Artificial':"이처럼 단 한 문장의 오역이 전체 문맥과 의미 그리고 화자의 의도를 오염시킬 수 있습니다. 언어 간의 다른 문화 차이와 소통 방법을 이해하고, 화자가 말하고자 하는 바를 제대로 전달하는 것이 올바른 번역입니다.",
    'Blanksystems':'국제 사회에 거센 한류의 영향으로 한국어 번역시장은 나날이 성장하고 있습니다. 그렇기에 번역가의 단순한 오역 실수도 국제적 논란으로 커질 위험이 있습니다. ',
    'Ourcore':"프로젝트의 문맥을 파악하고 현지에 맞는 번역을 제공해 상호간의 소통을 돕는 것은 블랭크의 핵심 서비스입니다. 저희 블랭크는 한국어에 특화 된 '언어 장벽 없는 세상'의 해답이 될 것입니다.",
    'Blankaims':'블랭크는 국내 공공기관과  글로벌 시장으로의 판로개척을 준비하는 국내기업 뿐 아니라  예능프로그램, 영화, 드라마, 소설 등을 해외로 수출하려는 방송사 및 미디어 플랫폼 그리고 한국에 대한 관심이 높아져 국내기업과 협업하고자 하는 글로벌 기업고객을 대상으로도 품질 높은 번역서비스를 제공하고 있습니다.',
    'Blankwill':"단순한 번역 플랫폼의 틀에서 벗어나 번역 종사자들의 업무프로세스 구조개선을 도우며 'K-컨텐츠 세계 보급화'에 없어서는 안 될 [한국어 특화 전문 번역 플랫폼]으로 자리매김 하기 위해 최선의 노력을 다 하겠습니다.",
    'Ourserviceprocess':'서비스 프로세스',
    'ProjectReview':"1. 프로젝트 확인",
    'Creationof':'2. 용어집 제작',
    'first-order':'3.  번역 작업 ',
    'Translationby':'4. 1차 검수',
    'accuracycheck':'5. 정확도 확인',
    'Proofreading':'6. 원어민 검수',
    'Verification':'7. 최종본 확인',
    'Delivery':'8. 완료본 제출',
    "Please.fill":"빈 칸에 내용을 입력 바랍니다.",
    "The.content":"내용 제한이 초과되었습니다.",
    "change":"변경",
    
    'cancel':'취소',
    'submit':'제출',
    'select':'선택',
    'english':'English',
    'korean':'Korean',
    'Youcan':'전문 분야는 세 개까지 선택할 수 있습니다.',
    'Preview':'Preview',
    "Optional":'(선택사항)',
    "description":'제목 및 설명 번역하기',
    "I":'원본 자막파일 있음',
    "warn":'Please reselect the document, only support pdf,txt,srt,hwp,doc file format',
    "addurl":'영상 링크 URL 추가',
    "vurl":'영상 링크 URL',
    "piv":"영상 링크 URL을 입력하세요",
    "AboutUs":'회사소개',
    "Service":"제공 서비스",
    "LanguageService":'언어 서비스',
    "ProTranslation":'전문 번역서비스',
    "YouTubeSubtitle":"유튜브 자막 번역서비스",
    'Business':'비즈니스',
    'VideoTranslation':'영상 전문 번역',
    'BusinessTranslation':'비즈니스 번역',
    'Support':'지원',
    'BlankSupport':'고객센터',
    'Terms':'이용약관',
    'Privacy':'개인정보 보호정책',
    'Employment':'채용문의',
    'BusinessTranslationInquiry':'기업 번역 문의',
    'Commerce':'통신판매업신고번호',
    'Number':'사업자등록번호',
    'Address':'주소',
    "ceo":"대표자",
    "kim":"김고은",
    "61472":'(61472) 광주광역시 동구 금남로 193-22',
    'cinfo':'회사 정보',
    'cop':'회사명',
    'fn':'담당자 성함',
    'pn':'연락처',

    "index.home":'홈',
    "index.language":'English',
    "index.service":"언어 서비스",
    "index.request":"번역 의뢰",
    'index.apply':'전문 번역가 지원하기',
    'index.sign':'Sign Up',
    'index.our':'제공 서비스',
    'index.contact':'문의하기',
    'index.contact.inquiry':'문의 유형',
    'index.contact.inquiry.en':'기업전문 번역',
    'index.contact.inquiry.la':'언어 데이터',
    'index.contact.inquiry.vi':'영상 자막 번역',
    'index.contact.inquiry.api':'API 솔루션',
    'index.contact.inquiry.pa':'파트너십',
    'index.contact.inquiry.ot':'기타문의',
    'index.contact.company':'회사명',
    'index.contact.full':'담당자 성함',
    'index.contact.Email':'이메일',
    'index.contact.Phone':'연락처',
    'index.contact.Select':'언어 선택',
    'index.contact.Source':'출발어',
    'index.contact.Target':'도착어',
    'index.contact.Please':'문의사항을 입력하세요.',
    'index.contact.Sample':'Sample File',
    'index.contact.Sample.top':'Drag and drop or click here to upload',
    'index.contact.Sample.bottom':'May upload up to a maximum of 2M Office, PDF, and SRT files under 5M',

    'login':'Login',
    'login.email':'이메일',
    'login.Password':'Password',
    'login.Stay':'Stay signed in',
    'login.Forgot':'Forgot your password?',
    'login.Not':'Not a Blank user yet? Sign up',
    'login.signup':'Sign up',
    'login.signup.withEmail':'Sign up with Email',
    'login.signup.Already':'Already have an account? ',
    'login.email.account':'Account ID',
    'login.email.Native':'Native Language',
    'login.email.By':'By signing up, you agree with the ',
    'login.email.Terms':'Terms ',
    'login.email.and':'and ',
    'login.email.Privacy':'Privacy Policy of Service',


    'apply.before.apply':"전문 번역가 지원하기",
    'apply.before.Register':"전문 번역가로 신청하고 온라인 테스트를 진행하세요.",
    'apply.before.You':"블랭크는 실력있는 전문 번역가님들의 지원을 기다립니다.",
    'apply.before.Submit':"이력서 제출 및 테스트 진행",
    'apply.before.Criteria':"지원 요건",
    'apply.before.In':"블랭크의 전문 번역가로 활동하기 위해서는 하단에 제시된 요건 중 1개 이상을 충족해야 합니다. ",
    'apply.before.Translating-related':"언어 관련 자격증 보유 및 번역 관련 학위 소지",
    'apply.before.More':"2년 이상 번역/통역 경력",
    'apply.before.Moretwo':"5년 이상 해외 거주 경험",
    'apply.before.Resume':"이력서 및 온라인 번역 테스트",
    'apply.before.Application':"위 요건에 부합하지 않더라도 경력 기재 이력서를 통해 검토 가능합니다. 이력서 제출 후 온라인 번역 테스트를 진행하세요.",
    'apply.before.How':"지원 방법",
    'apply.before.1':"1. ‘블랭크 전문 번역가 지원하기' 클릭",
    'apply.before.2':"2. 온라인 지원서 작성",
    'apply.before.3':"3. 온라인 번역 테스트 진행",
    'apply.before.4':"4. 지원서 제출",

    'apply.pro.Next':'다음',
    'apply.pro.Previous':'이전',
    'apply.pro.Save':'Save',
    'apply.pro.Qualification':'자격 사항',
    'apply.pro.Key':'주요 경력',
    'apply.pro.Sample':'샘플 번역 테스트',
    'apply.pro.Speciality':'전문 분야',
    'apply.pro.Add':'추가하기',
    'apply.pro.Daily':'전업 여부',
    'apply.pro.Full':'전업',
    'apply.pro.Part':'부업',
    'apply.pro.Words':'작업 가능 단어 수 / 1일',
    'apply.pro.Contact':'연락 가능한 이메일 주소',
    'apply.pro.Toreceive':'전문번역 요청을 위해 자주 사용하는 이메일 주소를 입력해주세요.',
    'apply.pro.Certification':'자격증 · 어학성적',
    'apply.pro.Certificate':'자격증 · 시험 명칭',
    'apply.pro.CertificateAuthority':'발급 기관',
    "apply.pro.aEducation":"교육 · 학교",
    'apply.pro.Score':'점수',
    'apply.pro.AddCertification':'자격증 및 어학성적 추가',
    'apply.pro.Experience':'해외 거주 경험',
    'apply.pro.Country':'거주 국가 / 지역',
    'apply.pro.Period':'기간',
    'apply.pro.Start':'시작 날짜',
    'apply.pro.End':'끝난 날짜',
    'apply.pro.erro':'제출하려면 유효한 기간을 입력하십시오',
    'apply.pro.AddOverseas':'해외 거주 경험 추가',
    'apply.pro.School':'학교명',
    'apply.pro.Major':'전공',
    'apply.pro.Minor':'부전공 / 복수전공',
    'apply.pro.AddSchool':'교육 또는 학교 추가',
    'apply.pro.Skills':'보유기술',
    'apply.pro.Pleaseenter':'보유기술을 입력하세요.',
    'apply.pro.CAT':'예) 사용가능한 CAT Tool (Studio, Passolo, Wordfast, MemoQ 등), 소프트웨어 (InDesign, AutoCAD, Photoshop, MS Word, MS Excel 등)',
    'apply.pro.Project':'프로젝트 명',
    'apply.pro.Client':'고객사',
    'apply.pro.Period':'기간',
    'apply.pro.AddExperience':'주요 경력 추가',
    'apply.pro.Original':'번역 원본',
    'apply.pro.Text':'Text here',
    'apply.pro.Please.input':'번역 내용을 입력해주세요. 기계번역 (파파고, 구글번역기 등)과 오역 제공은 제재 대상이 됩니다.',
    "apply.pro.Politics":"정치/마케팅",
    "apply.pro.Economics":'경제/금융',
    'apply.pro.Law':'법률/특허',
    'apply.pro.Medical':'의료/의학',
    'apply.pro.Science':'과학',
    'apply.pro.Engineering':'공학/기술',
    'apply.pro.IT':'IT(컴퓨터/애플리케이션)',
    'apply.pro.Construction':'건설/건축',
    'apply.pro.Publication':'출판/영상',
    'apply.pro.Game':'게임',
    'apply.pro.Humanities':'인문학/종교',
    'apply.pro.International':'국제',
    'apply.pro.Literature':'문학',
    'apply.pro.Education':'교육/학교/유학',
    'apply.pro.Culture':'문화/예술',
    'apply.pro.Beauty':'뷰티/패션',
    'apply.pro.Entertainment':'연예/스포츠',
    'apply.pro.Life':'생활/트렌드/음식',
    'apply.pro.Travel':'여행/역사',
    'apply.pro.commerce':'쇼핑',

    'tran.before.Paste':'YouTube URL 붙여넣기',
    'tran.before.Pleaseinput':'YouTube URL을 입력하세요.',
    'tran.before.Input':'입력 원본 텍스트',
    'tran.before.Pleaseselect':'번역을 요청하고자 하는 원문 파일을 입력하세요.',
    'tran.before.File':'파일',
    'tran.before.Subtitle':'자막',
    'tran.before.Drag':'드래그 앤 드롭 또는 여기를 클릭하여 업로드',
    'tran.before.Fileformat':'지원 파일 형식 : .pdf, .txt, .srt, .hwp, .doc ',
    'tran.before.Click':' YouTube URL 붙여넣기',
    'tran.before.The':'YouTube가 아닌 다른 URL을 인식할 수 없습니다.',
    
    'tran.Request':'요청 옵션 설정',
    'tran.Request.Select':'요청하려는 프로젝트의 상세옵션을 선택하고 최종 견적을 받아보세요.',
    'tran.Source':'번역 요청 원문',
    'tran.Reselect':'Reselect method',
    'tran.Re':'Re-import',
    'tran.warning':'요청파일에 번역이 필요한 이미지가 포함된 경우, 견적 산정 및 번역이 원활하지 못할 수 있습니다.',
    'tran.Selectlanguage':'언어 선택',
    'tran.Selectservice':'서비스 유형 선택',
    'tran.Standard':'Standard', 
    'tran.Translation':'번역',
    'tran.StandardPlus':'Standard Plus',
    'tran.Premium':'Premium',
    'tran.PremiumPlus':'Premium Plus',
    'tran.Native':'원어민 교정',
    'tran.Pro':'전문가 검수',
    'tran.Additional':'추가 정보',
    'tran.Specialty':'전문 분야',
    'tran.None':'None',
    'tran.Translationguidelines':'번역 지침',
    'tran.Pleaseprovide':'번역을 위해 메모할 것들을 제공해주세요 (개인 연락처 요청/제공 불가)',
    'tran.Summary':'요청 요약',
    'tran.Translateto':'도착 언어',
    'tran.Expected':'예상 견적',
    'tran.Estimated':'예상 작업 시간',
    'tran.Get':'죄종 견적 받기',
    'tran.word':'단어',




    'beensent.protran.your':'지원서가 성공적으로 제출되었습니다.',
    'beensent.protran.we':'제출한 지원서와 온라인 테스트를 심사 후,지원서에 기재된 이메일로 결과를 안내해 드립니다',
    'beensent.tran.your':'번역의뢰가 정상적으로 송부되었습니다.',
    'beensent.tran.we':'의뢰주신 번역내용을 검토한 후, 빠른 시간 안에 연락드리겠습니다. 감사합니다.',
    'beensent.cont.your':'문의내용이 정상적으로 송부되었습니다.',
    'beensent.cont.we':'문의주신 내용을 검토한 후, 빠른 시간 안에 연락드리겠습니다. 감사합니다.',
    
}
export {
    ko_KR
}   