import style from "../css/home.scss";
import homebanner from "../images/homebanner.jpg";
import image14 from "../images/illustration_home_translation.png";
import image13 from "../images/image13.png";
import image15 from "../images/image15.png";
import image16 from "../images/illustration_home_interpretation.png";
import avatar from "../images/Avatar.png";
import avatar1 from "../images/Avatar_1.png";
import avatar2 from "../images/Avatar_2.png";
import JTBC from "../images/logo_JTBC.png";
import tvN from "../images/logo_tvn.png";
import MBC from "../images/logo_MBC.png";
import sbs from "../images/logo_sbs.png";
import kbs from "../images/logo_kbs.png";
import ebs from "../images/logo_ebs.png";
import snailoflove from "../images/logo_snailoflove.png";
import OBS from "../images/logo_obs.png";
import achannel from "../images/logo_achannel.png";
import forcetv from "../images/logo_forcetv.png";
import YTN from "../images/logo_ytn.png";
import gidp from "../images/logo_gidp.png";
import arirang from "../images/logo_arirang.png";
import tvchosun from "../images/logo_tvchosun.png";
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import Expanse from "react-expanse";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/css/bundle";

const Home = props => {
  const companieslogo = [achannel, snailoflove, arirang, tvchosun, ebs, tvN, forcetv, YTN, gidp, JTBC, kbs, MBC, OBS, sbs];
  const [our, setOur] = useState(false);
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const [blank, setBlank] = useState(false);
  const [service, setService] = useState(false);
  const [business, setBusiness] = useState(false);
  const [support, setSupport] = useState(false);
  const [World, setWorld] = useState(false);
  const [mobile, setMobile] = useState(false);

  const getWindowSize = () => ({
    innerWidth: window.innerWidth,
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const handleResize = () => {
    setWindowSize(getWindowSize());
  };
  useEffect(() => {
    // 监听
    window.addEventListener("resize", handleResize);
    // 销毁
    return () => window.removeEventListener("resize", handleResize);
  });
  useEffect(() => {
    const width = windowSize.innerWidth;
    if (width < 720 || width > 300) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowSize.innerWidth]);

  const goRequest = e => {
    props.history.push("/translation");
  };

  useEffect(() => {
    if (t("eok") === "e") {
      setWorld(true);
    } else if (t("eok") === "k") {
      setWorld(false);
    }
  }, [t("eok")]);
  return (
    <>
      <div>
        <div className={style.banner}>
          <div>
            {World ? (
              <p>
                {t("AWorld")} [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;] {t("Breaking")}
              </p>
            ) : (
              <p>
                언어 장벽 없는 세상을 위한
                <br />
                한국어 특화 전문 번역 플랫폼
              </p>
            )}

            {World ? (
              <span>{t("home.widen")}</span>
            ) : (
              <span>
                블랭크의 전문 번역 및 현지화 서비스를 통해 전세계로 고객층을 확장하세요.
                <br />
                '한국어 특화 전문 번역서비스'로 목표 시장에 정확한 브랜드 메시지를 전달합니다.
                <br />
                신속하고 전문적인 번역 서비스를 합리적인 가격에 만나보세요.
                <br />
                블랭크는 귀사의 해외 판로개척 및 글로벌 사업의 든든한 동반자가 되겠습니다.
              </span>
            )}
          </div>
          <img src={homebanner} alt="" />
        </div>
        <div className={style.service}>
          <div>
            <p>{t("Our")}</p>
            <span>{t("Reliable")}</span>
            <div>
              <div>
                <img src={image14} alt="" />
                <span>{t("tran.Translation")}</span>
              </div>
              <div>
                <img src={image13} alt="" />
                <span>{t("Localization")}</span>
              </div>
              <div>
                <img src={image15} alt="" />
                <span>{t("Editing")}</span>
              </div>
              <div>
                <img src={image16} alt="" />
                <span>{t("Interpretation")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={style.clients}>
          <div>
            <p>{t("Testimony")}</p>
          </div>
          <div>
            <div>
              <img src={avatar} alt="" />
              <p>“ {t("Meticulous")} ”</p>
              <span>{t("Thenature")}</span>
              <i>{t("SujinPark")}</i>
            </div>
            <div>
              <img src={avatar1} alt="" />
              <p>“ {t("Competitive")} ”</p>
              <span>{t("Having")}</span>
              <i>{t("Areum")}</i>
            </div>
            <div>
              <img src={avatar2} alt="" />
              <p>“ {t("High-Quality")} ”</p>
              <span>{t("Sincewe")}</span>
              <i>{t("SeoyeongLee")}</i>
            </div>
          </div>
        </div>

        <div className={style.companies}>
          <div>
            <p>{t("Listof")}</p>
            <Swiper
              // install Swiper modules
              className={style.swiper}
              modules={[Autoplay]}
              speed={10000}
              allowTouchMove={false}
              autoplay={{ delay: 1, stopOnLastSlide: false, disableOnInteraction: false }}
              loop={{}}
              // onSwiper={swiper => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide style={{ width: mobile ? 1061 : 1061 }}>
                <div className={style.slide}>
                  {companieslogo.map((item, index) => (
                    <img src={item} key={index}></img>
                  ))}
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: mobile ? 1061 : 1061 }}>
                <div className={style.slide}>
                  {companieslogo.map((item, index) => (
                    <img src={item} key={index}></img>
                  ))}
                </div>
              </SwiperSlide>
              ...
            </Swiper>
          </div>
        </div>
        <div className={style.quote}>
          <div>
            <div>
              <p>{t("Getan")}</p>
              <span>{t("Theeasy")}</span>
              <span>{t("buy")}</span>
            </div>
            <button onClick={goRequest}>{t("Instantquote")}</button>
          </div>
        </div>
        <div className={style.foot}>
          <div className={style.mobiletop}>
            <div onClick={() => setBlank(!blank)}>
              Blank<i className={!blank ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={blank}>
              <div className={style.topexpanse}>
                <span>{t("AboutUs")}</span>
              </div>
            </Expanse>
            <div onClick={() => setService(!service)}>
              {t("Service")}
              <i className={!service ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={service}>
              <div className={style.topexpanse}>
                <span>{t("LanguageService")}</span>
                <span>{t("ProTranslation")}</span>
                <span>{t("YouTubeSubtitle")}</span>
              </div>
            </Expanse>
            <div onClick={() => setBusiness(!business)}>
              {t("Business")}
              <i className={!business ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={business}>
              <div className={style.topexpanse}>
                <span>{t("VideoTranslation")}</span>
                <span>{t("BusinessTranslation")}</span>
              </div>
            </Expanse>
            <div onClick={() => setSupport(!support)}>
              {t("Support")}
              <i className={!support ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}></i>
            </div>
            <Expanse show={support}>
              <div className={style.topexpanse}>
                <span>{t("BlankSupport")}</span>
                <span>{t("Terms")}</span>
                <span>{t("Privacy")}</span>
              </div>
            </Expanse>
          </div>
          <div className={style.top}>
            <div>
              <p>Blank</p>
              <span>{t("AboutUs")}</span>
            </div>
            <div>
              <p>{t("Service")}</p>
              <span>{t("LanguageService")}</span>
              <span>{t("ProTranslation")}</span>
              <span>{t("YouTubeSubtitle")}</span>
            </div>
            <div>
              <p>{t("Business")}</p>
              <span>{t("VideoTranslation")}</span>
              <span>{t("BusinessTranslation")}</span>
            </div>
            <div>
              <p>{t("Support")}</p>
              <span>{t("BlankSupport")}</span>
              <span>{t("Terms")}</span>
              <span>{t("Privacy")}</span>
            </div>
          </div>
          <div className={style.bottom}>
            <div>
              <p>{t("Employment")}</p>
              <span>blank.translate@gmail.com</span>
            </div>
            <div>
              <p>{t("ceo")}</p>
              <span>{t("kim")}</span>
            </div>
            <div>
              <p>{t("BusinessTranslationInquiry")}</p>
              <span>blank.translate@gmail.com</span>
            </div>
            <div>
              <p>{t("Commerce")}</p>
              <span>2021-GwangjuGwangsan-0287</span>
            </div>
            <div>
              <p>{t("Number")}</p>
              <span>236-38-00655</span>
            </div>
            <div>
              <p>{t("Address")}</p>
              <span>{t("61472")}</span>
            </div>
          </div>
          <span>©2024 Beyondary. All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

export default Home;
