import { createStore,combineReducers } from "redux";

function countReducer(state = {language:"en"},action){
    switch (action.type) {
        case "ko":
           return {language:"ko"}
        case "en":
            return{language:"en"}
        default:
            return state;
    }
}
const reducers = combineReducers({count:countReducer})
const store = createStore(countReducer)


export default store