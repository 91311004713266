import style from "../css/index.scss";
import React, { useEffect, useRef, useState } from "react";
import { renderRoutes } from "react-router-config";
import { useIntl } from "react-intl";
import type { MenuProps } from "antd";
import { Dropdown, Menu, message, Space, Button, Modal, Input, Upload } from "antd";
import logoimg from "../images/logo-blank.png";
import { Provider, connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { en_US } from "../locale/en_US";
import { ko_KR } from "../locale/ko_KR";
import Expanse from "react-expanse";
import store from "../router/store";
import emailjs from "emailjs-com";
import apiKeys from "../router/keys";

const { TextArea } = Input;
const { Dragger } = Upload;

const messages = {
  en: en_US,
  ko: ko_KR,
};
function stateToProps(state) {
  return {
    counter: state.language,
  };
}
function actionToProps(dispatch) {
  return {
    en: () => {
      dispatch({ type: "en" });
    },
    ko: () => {
      dispatch({ type: "ko" });
    },
  };
}

const Index = props => {
  emailjs.init("MgPT_AFf_ROdxwkzr");
  const a = useRef();
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const [storage, setStorage] = useState();
  const [hover, setHover] = useState("/home");
  const [changeLanague, setChangeLanague] = useState("English");
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [menuSource, setMenuSource] = useState(t("index.contact.Source"));
  const [menuTarget, setMenuTarget] = useState(t("index.contact.Target"));
  const [textarea, setTextarea] = useState();
  const [mobilemenu, setMobilemenu] = useState(false);
  const [index, setIndex] = useState("en");
  const [type, setType] = useState(t("index.contact.inquiry.en"));
  const [filetype, setFileType] = useState();
  const [data, setData] = useState();
  const [upload, setUpload] = useState();
  const [ok, setOk] = useState(false);
  const [aurl, setUrl] = useState([{ url: "" }]);
  const [allurl, setAllUrl] = useState([]);
  const [urlnum, setUrlnum] = useState(0);
  const [com, setCom] = useState();
  const [full, setFull] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const [loadings, setLoadings] = useState(false);

  const getWindowSize = () => ({
    innerWidth: window.innerWidth,
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const handleResize = () => {
    setWindowSize(getWindowSize());
  };

  //获取窗口大小
  useEffect(() => {
    // 监听
    window.addEventListener("resize", handleResize);
    // 销毁
    return () => window.removeEventListener("resize", handleResize);
  });

  //监听窗口大小
  useEffect(() => {
    const width = windowSize.innerWidth;
    if (width === 720 || width === 300) {
      setMobilemenu(false);
    }
  }, [windowSize.innerWidth]);

  store.subscribe(() => {
    setIndex(store.getState().language);
  });

  const showModal = () => {
    setOpen(true);
  };

  const success = () => {
    message.success({
      content: t("Please.fill"),
      className: style.iswarning,
      style: {
        marginTop: "80px",
      },
    });
  };
  const success2 = () => {
    message.success({
      content: t("The.content"),
      className: style.iswarning,
      style: {
        marginTop: "80px",
      },
    });
  };

  const handleOk = () => {
    if (com === "" || full === "" || email === "" || phone === "" || textarea === undefined || menuSource === t("index.contact.Source") || menuTarget === t("index.contact.Target")) {
      success();
    } else {
      if (type === t("index.contact.inquiry.vi")) {
        if (aurl[urlnum].url === "") {
          return;
        } else {
          setLoadings(true);
          aurl.map((item, index) => {
            const num = [...aurl];
            const i = index + 1;
            const all = allurl;
            all.push("<p>" + i + "." + item.url + "</p>");
            setAllUrl(all);
          });
          emailjs
            .send("service_rmh93g9", "template_v3qy3w2", {
              my_html:
                "<h1>Contact us</h1> <div> <h2>Inquiry type</h2> <h4>" +
                type +
                "</h4> <div>" +
                allurl +
                "</div> </div> <div> <h2>Company</h2> <span>" +
                com +
                "</span> </div> <div> <h2>Full Name</h2> <span>" +
                full +
                "</span> </div> <div> <h2>Email</h2> <span>" +
                email +
                "</span> </div> <div> <h2>Phone</h2> <span>" +
                phone +
                "</span> </div> <div> <h2>Select language</h2> <span>" +
                menuSource +
                "&nbsp;&nbsp;&nbsp;&nbsp;&#8594;&nbsp;&nbsp;&nbsp;&nbsp;" +
                menuTarget +
                "</span> </div> <div> <h2>Contact us</h2> <span>" +
                textarea +
                "</span> </div>",
            })
            .then(
              result => {
                setOpen(false);
                setLoadings(false);
                props.history.push({
                  pathname: "/beensent",
                  query: {
                    id: "protran",
                  },
                });
              },
              error => {
                alert("Sent failed");
              }
            );
        }
      } else {
        setLoadings(true);
        emailjs
          .send("service_rmh93g9", "template_v3qy3w2", {
            my_html:
              "<h1>Contact us</h1> <div> <h2>Inquiry type</h2> <h5>" +
              type +
              "</h5> <p></p> </div> <div> <h2>Company</h2> <span>" +
              com +
              "</span> </div> <div> <h2>Full Name</h2> <span>" +
              full +
              "</span> </div> <div> <h2>Email</h2> <span>" +
              email +
              "</span> </div> <div> <h2>Phone</h2> <span>" +
              phone +
              "</span> </div> <div> <h2>Select language</h2> <span>" +
              menuSource +
              "&nbsp;&nbsp;&nbsp;&nbsp;&#8594;&nbsp;&nbsp;&nbsp;&nbsp;" +
              menuTarget +
              "</span> </div> <div> <h2>Contact us</h2> <span>" +
              textarea +
              "</span> </div>",
          })
          .then(
            result => {
              setOpen(false);
              setLoadings(false);
              setUrl([{ url: "" }]);
              props.history.push({
                pathname: "/beensent",
                query: {
                  id: "cont",
                },
              });
            },
            error => {
              return;
            }
          );
      }
    }
  };

  const addUrl = e => {
    const k = urlnum + 1;
    aurl.map((item, index) => {
      const num = [...aurl];
      if (index < 9) {
        const i = urlnum;
        if (num[i].url === "") {
          return;
        } else {
          num.push({ url: "" });
          setUrl(num);
          setUrlnum(k);
        }
      } else {
        return;
      }
    });
  };

  const onAddurl = (e, index) => {
    setUrl(url =>
      aurl.map((item, i) => {
        if (i != index) {
          return item;
        } else {
          return {
            ...item,
            url: e.target.value,
          };
        }
      })
    );
  };

  const handleCancel = () => {
    setOpen(false);
    setMenuSource("Source language");
    setMenuTarget("Target language");
  };

  const gologin = () => {
    props.history.push("/login");
  };

  const gobeforeProTranslator = () => {
    props.history.push("/beforeprotranslator");
  };

  const goHome = () => {
    props.history.push("/home");
  };

  const onClickLanguage: MenuProps["onClick"] = e => {
    const id = e.domEvent.target.id;
    setChangeLanague(id);
    if (id === "English") {
      props.en();
    } else if (id === "한국어 ") {
      props.ko();
    }
  };

  const goChildren = e => {
    const whichmenu = sessionStorage.getItem("menu");
    if (e.domEvent.target.id != "none") {
      if (e.domEvent.target.id === "/homeour") {
        props.history.push({
          pathname: "/homeour",
          our: true,
        });
      }
      props.history.push(e.domEvent.target.id);
      setHover(e.domEvent.target.className);
      if (!whichmenu) {
        sessionStorage.setItem("menu", e.domEvent.target.className);
      } else {
        sessionStorage.removeItem("menu");
        sessionStorage.setItem("menu", e.domEvent.target.className);
      }
    } else {
      return;
    }
  };

  const changetype = e => {
    setType(e.domEvent.target.id);
    if (e.domEvent.target.id != t("index.contact.inquiry.vi")) {
      setUrl([{ url: "" }]);
      setUrlnum(0);
    }
  };

  const onClickMenu = e => {
    // message.info(`Click on item ${key}`);
    const id = e.domEvent.target.id;
    const classname = e.domEvent.target.className;
    if (classname === "menu1") {
      if (id === "English") {
        setMenuSource("English");
        setMenuTarget("한국어 ");
      } else {
        setMenuSource("한국어 ");
        setMenuTarget("English");
      }
    } else if (classname === "menu2") {
      if (id === "English") {
        setMenuTarget("English");
        setMenuSource("한국어 ");
      } else {
        setMenuTarget("한국어 ");
        setMenuSource("English");
      }
    }
  };

  const onChangeTextarea = e => {
    setTextarea(e.target.value);
  };

  useEffect(() => {
    setType(t("index.contact.inquiry.en"));
    setMenuSource(t("index.contact.Source"));
    setMenuTarget(t("index.contact.Target"));
  }, [t("cancel")]);

  useEffect(() => {
    const path = window.location.pathname;
    const whichmenu = sessionStorage.getItem("menu");
    if (whichmenu) {
      if (path === "/home") {
        setHover("/home");
      } else {
        setHover(whichmenu);
      }
    }
    const origin = window.location.origin + "/";
    if (window.location.href === origin) {
      props.history.push("/home");
    } else if (path === "/home" || path === "/") {
      setHover("/home");
    }
    if (path != "/mobileheader") {
      setMobilemenu(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setType(t("index.contact.inquiry.en"));
    setMenuSource(t("index.contact.Source"));
    setMenuTarget(t("index.contact.Target"));
  }, [t("cancel")]);
  const menulanguage = (
    <Menu
      onClick={onClickLanguage}
      items={[
        {
          label: (
            <div id="English" style={{ width: 110, height: 30, textAlign: "left", paddingTop: 5, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              English
              <i className="ri-check-line" style={{ display: changeLanague === "English" ? "block" : "none" }}></i>
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div id="한국어 " style={{ width: 110, height: 30, textAlign: "left", paddingTop: 5, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              한국어
              <i className="ri-check-line" style={{ display: changeLanague === "한국어 " ? "block" : "none" }}></i>
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );
  const menulanguageservice = (
    <Menu
      onClick={goChildren}
      items={[
        {
          label: (
            <div id="homeour" className="menulanguageservice" style={{ width: 180, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.our")}
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div id="/beforeprotranslator" className="menulanguageservice" style={{ width: 180, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.apply")}
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );
  const menurequest = (
    <Menu
      onClick={goChildren}
      items={[
        {
          label: (
            <div id="/translation" className="menurequest" style={{ width: 130, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.request")}
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div onClick={showModal} id="none" className="menurequest" style={{ width: 126, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact")}
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );
  const menuinquirytype = (
    <Menu
      onClick={changetype}
      items={[
        {
          label: (
            <div id={t("index.contact.inquiry.en")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.en")}
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div id={t("index.contact.inquiry.la")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.la")}
            </div>
          ),
          key: "2",
        },
        {
          label: (
            <div id={t("index.contact.inquiry.vi")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.vi")}
            </div>
          ),
          key: "3",
        },
        {
          label: (
            <div id={t("index.contact.inquiry.api")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.api")}
            </div>
          ),
          key: "4",
        },
        {
          label: (
            <div id={t("index.contact.inquiry.pa")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.pa")}
            </div>
          ),
          key: "5",
        },
        {
          label: (
            <div id={t("index.contact.inquiry.ot")} style={{ width: 512, height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400 }}>
              {t("index.contact.inquiry.ot")}
            </div>
          ),
          key: "6",
        },
      ]}
    />
  );
  const menu1 = (
    <Menu
      onClick={onClickMenu}
      items={[
        {
          label: (
            <div id="English" className="menu1" style={{ width: "100%", height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              English
              <i className="ri-check-line" style={{ display: menuSource === "English" ? "block" : "none" }}></i>
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div id="한국어 " className="menu1" style={{ width: "100%", height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              한국어
              <i className="ri-check-line" style={{ display: menuSource === "한국어 " && menuSource != "Source language" ? "block" : "none" }}></i>
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );
  const menu2 = (
    <Menu
      onClick={onClickMenu}
      items={[
        {
          label: (
            <div id="English" className="menu2" style={{ width: "100%", height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              English
              <i className="ri-check-line" style={{ display: menuTarget === "English" ? "block" : "none" }}></i>
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div id="한국어 " className="menu2" style={{ width: "100%", height: 30, textAlign: "left", paddingTop: 5, fontSize: 14, fontWeight: 400, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              한국어
              <i className="ri-check-line" style={{ display: menuTarget === "한국어 " && menuTarget != "Target language" ? "block" : "none" }}></i>
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );

  return (
    <>
      <div className={style.header}>
        <div className={style.headerLeft}>
          <div className={style.logo} onClick={e => goHome(e)}>
            <img src={logoimg} alt="" />
          </div>
          <Dropdown overlay={menulanguageservice} placement="bottom" className={hover === "menulanguageservice" ? style.spanclick : null}>
            <Button>{t("index.service")}</Button>
          </Dropdown>
          <Dropdown overlay={menurequest} placement="bottom" className={hover === "menurequest" ? style.spanclick : null}>
            <Button>{t("index.request")}</Button>
          </Dropdown>
        </div>
        <Modal
          getContainer={false}
          title={t("index.contact")}
          className={style.modal}
          open={open}
          confirmLoading={confirmLoading}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="Cancel" onClick={handleCancel} className={style.cancel}>
              {t("cancel")}
            </Button>,
            <Button key="Select" type="primary" loading={loadings} onClick={handleOk} className={style.select}>
              {t("submit")}
            </Button>,
          ]}
        >
          <span className={style.plase}>{t("Pleasecomplete")}</span>
          <p>{t("index.contact.inquiry")}</p>
          <Dropdown overlay={menuinquirytype} placement="bottom" className={style.inquirytype}>
            <Button>
              {type}
              <i className="ri-arrow-down-s-line"></i>
            </Button>
          </Dropdown>
          <div>
            <p>{t("index.contact.company")}</p>
            <p>{t("index.contact.full")}</p>
          </div>
          <Input onChange={e => setCom(e.target.value)} placeholder={t("please.input")} />
          <Input onChange={e => setFull(e.target.value)} placeholder={t("please.input")} />
          <div>
            <p>{t("index.contact.Email")}</p>
            <p>{t("index.contact.Phone")}</p>
          </div>
          <Input onChange={e => setEmail(e.target.value)} type="email" placeholder={t("please.input")} />
          <Input onChange={e => setPhone(e.target.value)} type="tel" placeholder={t("please.input")} />
          <div>
            <p>{t("index.contact.Select")}</p>
          </div>
          <div className={style.dropdown}>
            <Dropdown overlay={menu1}>
              <div onClick={e => e.preventDefault()}>
                <Space className={menuSource === "Source language" ? "" : style.divclick}>{menuSource}</Space>
                <i className="ri-arrow-down-s-line"></i>
              </div>
            </Dropdown>
            <i className="ri-arrow-right-line"></i>
            <Dropdown overlay={menu2}>
              <div onClick={e => e.preventDefault()}>
                <Space className={menuTarget === "Target language(s)" ? "" : style.divclick}>{menuTarget}</Space>
                <i className="ri-arrow-down-s-line"></i>
              </div>
            </Dropdown>
          </div>
          {type === t("index.contact.inquiry.vi") ? (
            <div className={style.url}>
              <p>{t("vurl")}</p>
              {aurl.map((item, index) => (
                <div key={index}>
                  <Input className={index > 0 ? style.input : ""} onChange={e => onAddurl(e, index)} value={item.url} placeholder={t("piv")} />
                  {index > 0 ? (
                    <button
                      onClick={e => {
                        const arr = [...aurl];
                        const i = index - 1;
                        arr.splice(index, 1);
                        setUrlnum(i);
                        setUrl(arr);
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <span onClick={e => addUrl(e)}>
                <i className="ri-add-line"></i>
                {t("addurl")}
              </span>
            </div>
          ) : (
            ""
          )}
          <div>
            <p>{t("index.contact")}</p>
            <TextArea className={style.textarea} placeholder={t("index.contact.Please")} showCount maxLength={300} onChange={onChangeTextarea} autoSize="ture" />
          </div>
        </Modal>
        <div className={style.headerRight}>
          <Dropdown overlay={menulanguage} placement="bottom" className={style.engorkor}>
            <div onClick={e => e.preventDefault()}>
              <Space>{changeLanague}</Space>
              <i className="ri-arrow-down-s-fill"></i>
            </div>
          </Dropdown>
          <button onClick={() => props.history.push("/beforetranslation")}>{t("index.request")}</button>
          <div
            className={style.mobile}
            onClick={() => {
              setMobilemenu(!mobilemenu);
              if (!mobilemenu) {
                props.history.push({
                  pathname: "/mobileheader",
                  query: {
                    mobile: true,
                  },
                });
              } else {
                props.history.push("/home");
              }
            }}
          >
            <i className={mobilemenu ? "ri-close-line" : "ri-menu-line"}></i>
          </div>
        </div>
      </div>
      <IntlProvider locale={index} messages={messages[index]}>
        {renderRoutes(props.route.routes)}
      </IntlProvider>
    </>
  );
};

export default connect(stateToProps, actionToProps)(Index);
