import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import style from "../css/beensent.scss";
import tick1 from "../images/tick1.png";

const Beensent = props => {
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const [storage, setStorage] = useState();

  useEffect(() => {
    if (props.location.query === undefined) {
      props.history.push("/");
    } else {
      setStorage(props.location.query.id);
    }
  }, []);

  return (
    <div className={style.beensent}>
      <img src={tick1} alt="" />
      <div className={storage === "protran" ? style.div : style.divno}>
        <p>{t("beensent.protran.your")}</p>
        <span>{t("beensent.protran.we")}</span>
      </div>
      <div className={storage === "tran" ? style.div : style.divno}>
        <p>{t("beensent.tran.your")}</p>
        <span>{t("beensent.tran.we")}</span>
      </div>
      <div className={storage === "cont" ? style.div : style.divno}>
        <p>{t("beensent.cont.your")}</p>
        <span>{t("beensent.cont.we")}</span>
      </div>
    </div>
  );
};

export default Beensent;
